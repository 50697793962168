import uniq from 'lodash/uniq';
import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import i18n from 'i18n';

import AdminApi from 'api/AdminApi';
import { usersGlobalSelector } from 'features/users/usersSlice';
import { showHttpError } from 'features/httpError/httpErrorSlice';
import { ALL_ORGANISATIONS_KEY } from 'hooks/useOrganisationsSelectOptions';

export const GROUPS_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
};

/*
GROUP OBJECT:
  origin:"Native"
  organisation_id:12
  member_count:4
  origin_id:null
  is_public:false
  title:"Teamwire.eu"
  id:"2302",
  members: []
  "allow_federation": false,
  "federation_name": null,
  "title": "777moje"
*/

const groupsAdapter = createEntityAdapter({
  selectId: group => group.id,
});

export const initialFilters = {
  limit: 10,
  members_max: null,
  members_min: null,
  offset: 0,
  ordering: 'title',
  search: null,
  origin: null,
  public: null,
};

export const initialCircleDetailsFilters = {
  limit: 10,
  offset: 0,
  ordering: 'last_name',
  search: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState: groupsAdapter.getInitialState({
    isLoading: false,
    isDownloadingCsvData: false,
    lastUpdated: null,
    groupsLoading: [],
    filters: initialFilters,
    circleDetailsFilters: initialCircleDetailsFilters,
    circleDetailsMembers: [],
    circleDetailsMembersCount: 0,
    memberCount: {},
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    initCsvDownload: state => {
      state.isDownloadingCsvData = true;
    },
    finishCsvDownload: state => {
      state.isDownloadingCsvData = false;
    },
    initLoadingGroup: (state, action) => {
      const { id } = action.payload;
      if (!state.groupsLoading.includes(id)) {
        state.groupsLoading.push(id);
      }
    },
    finishLoadingGroup: (state, action) => {
      const { id } = action.payload;
      state.groupsLoading = state.groupsLoading.filter(groupLoading => groupLoading !== id);
    },
    updateGroups: (state, action) => {
      const { addedGroups = [], deletedGroupsIds = [] } = action.payload;

      state.lastUpdated = new Date().toUTCString();

      groupsAdapter.setAll(state, addedGroups);
      groupsAdapter.removeMany(state, deletedGroupsIds);
    },
    updateGroup: (state, action) => {
      const { id, props = {} } = action.payload;
      groupsAdapter.updateOne(state, { id, changes: props });
    },
    updateMembersGroup: (state, action) => {
      const { id, usersIds = [], type } = action.payload;
      if (!state.ids.includes(id)) {
        return;
      }

      const members = state.entities[id].members;
      if (type === 'ADD') {
        members.push(...usersIds);
        state.entities[id].members = uniq(members);
      }
      if (type === 'DELETE') {
        state.entities[id].members = members.filter(memberId => !usersIds.includes(memberId));
      }
      state.entities[id].member_count = state.entities[id].members.length;
    },
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateCircleDetailsFilters: (state, action) => {
      state.circleDetailsFilters = { ...state.circleDetailsFilters, ...action.payload };
    },
    updateCircleDetailsMembers: (state, action) => {
      state.circleDetailsMembers = action.payload;
    },
    updateCircleDetailsMembersCount: (state, action) => {
      state.circleDetailsMembersCount = action.payload;
    },
    updateMinMax: (state, action) => {
      const { min_member_count, max_member_count } = action.payload;

      state.memberCount = {
        min: min_member_count,
        max: max_member_count,
      };
    },
  },
});

export const {
  initLoading,
  finishLoading,
  initCsvDownload,
  finishCsvDownload,
  initLoadingGroup,
  finishLoadingGroup,
  updateGroups,
  updateGroup,
  updateMembersGroup,
  updateCount,
  updateFilters,
  updateCircleDetailsFilters,
  updateCircleDetailsMembers,
  updateCircleDetailsMembersCount,
  updateMinMax,
} = groupsSlice.actions;

// SELECTORS
const getGroupsState = state => state.groups;
export const groupsGlobalSelector = groupsAdapter.getSelectors(getGroupsState);
export const getGroupsLastUpdated = createSelector([getGroupsState], state => state.lastUpdated);
export const getIsGroupsLoading = createSelector([getGroupsState], state => state.isLoading);
export const getIsDownloadingCsvData = createSelector(
  [getGroupsState],
  state => state.isDownloadingCsvData,
);
export const getGroupsList = groupsGlobalSelector.selectAll;
export const getMemberCount = state => state.groups.memberCount;

export const getGroupsListByOrganisation = createSelector(
  [getGroupsList, (_, organisationId) => parseInt(organisationId)],
  (groups, organisationId) => {
    let list = groups;
    if (organisationId) {
      list = groups.filter(group => group.organisation_id === organisationId);
    }
    return list.map(o => ({ ...o, disabled: Boolean(o.federation) }));
  },
);

export const getGroupById = groupsGlobalSelector.selectById;
export const getUsersMembersByGroupId = createSelector(
  groupsGlobalSelector.selectEntities,
  (_, groupId) => groupId,
  state => state.users.entities,
  (entities, groupId, userEntities) => {
    const group = entities[groupId];
    if (!group) {
      return [];
    }
    const isFederated = Boolean(group.federation);
    const { members = [] } = group;

    const filteredUsers = members.map(memberId => {
      const user = userEntities[memberId];
      if (!user) {
        return {
          id: memberId,
          first_name: i18n.t('USER_OF_ANOTHER_ORG'),
          last_name: '-',
          email: '-',
        };
      }
      return user;
    });

    return filteredUsers.map(user => ({ ...user, disabled: isFederated }));
  },
);

export const getUsersToAddByGroupId = createSelector(
  groupsGlobalSelector.selectEntities,
  (_, groupId) => groupId,
  usersGlobalSelector.selectAll,
  state => state.profile.data,
  (entities, groupId, users, user) => {
    const isSuperAdmin = user.super_admin;
    const group = entities[groupId];
    const { members = [] } = group;
    const filteredUsers = users.filter(user => {
      if (members.includes(user.id) || user.federation) {
        return false;
      }

      if (!isSuperAdmin) {
        if (user.organisation_id !== group.organisation_id) {
          return false;
        }
      }

      return true;
    });
    return filteredUsers.map(user => ({ ...user }));
  },
);

export const getGroupUsersOrganisationId = state => {
  const loggedUser = state.profile.data;

  if (loggedUser.super_admin) {
    return ALL_ORGANISATIONS_KEY;
  }

  return loggedUser.organisation_id;
};

export const getGroupsCount = state => state.groups.count;

export const getFilters = state => state.groups.filters;

export const getCircleDetailsFilters = state => state.groups.circleDetailsFilters;

export const getCircleDetailsMembers = state =>
  state.groups.circleDetailsMembers.map(member => {
    if (member.first_name === null && member.last_name === null && member.email === null) {
      return {
        id: member.id,
        first_name: i18n.t('USER_OF_ANOTHER_ORG'),
        last_name: '-',
        email: '-',
      };
    }
    return member;
  });

export const getCircleDetailsMembersCount = state => state.groups.circleDetailsMembersCount;

// THUNKS
export const getGroupsCsvData = async (dispatch, getState) => {
  try {
    dispatch(initCsvDownload());

    const {
      organisations,
      groups: { filters },
    } = getState();

    let groupsCsv;

    const organisationId = organisations.selectedOrganisationId;

    const { data } = await AdminApi.getAllGroups();

    if (organisationId === 'ALL_ORGANISATIONS') {
      groupsCsv = data;
    } else {
      groupsCsv = data.filter(group => group.organisation_id === organisationId);
    }

    groupsCsv = groupsCsv
      .filter(group => {
        if (filters.origin !== null && filters.origin !== group.origin) {
          return false;
        }

        if (filters.public !== null && JSON.parse(filters.public) !== group.is_public) {
          return false;
        }

        if (
          filters.members_min !== null &&
          filters.members_max !== null &&
          (group.member_count < filters.members_min || group.member_count > filters.members_max)
        ) {
          return false;
        }

        return !(
          filters.search !== null &&
          !group.title.toLowerCase().includes(filters.search.toLowerCase())
        );
      })
      .sort((groupA, groupB) => {
        let sortFactor = 1;
        if (filters.ordering.startsWith('-')) {
          sortFactor = -1;
        }

        if (filters.ordering.includes('title')) {
          return groupA.title.toUpperCase().localeCompare(groupB.title.toUpperCase()) * sortFactor;
        }

        if (filters.ordering.includes('member_count')) {
          return (groupA.member_count - groupB.member_count) * sortFactor;
        }

        if (filters.ordering.includes('is_public')) {
          return (
            groupA.is_public
              .toString()
              .toUpperCase()
              .localeCompare(groupB.is_public.toString().toUpperCase()) * sortFactor
          );
        }

        if (filters.ordering.includes('origin')) {
          return (
            groupA.origin.toUpperCase().localeCompare(groupB.origin.toUpperCase()) * sortFactor
          );
        }

        return 0;
      });

    return groupsCsv;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishCsvDownload());
  }
};

export const fetchGroups = async (dispatch, getState) => {
  const { groups, organisations, profile } = getState();

  let organisationId =
    organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
      ? 'all'
      : organisations.selectedOrganisationId;

  if (organisationId === null) {
    organisationId = profile?.data?.organisation_id;
  }

  dispatch(initLoading());

  try {
    const {
      data: { count, results, min_member_count, max_member_count },
    } = await AdminApi.getPaginatedGroups({ organisationId, filters: groups.filters });

    dispatch(updateGroups({ addedGroups: results }));
    dispatch(updateCount(count));
    dispatch(updateMinMax({ min_member_count, max_member_count }));
    return GROUPS_RESPONSES.OK;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoading());
  }
};

export const fetchGroup = id => async (dispatch, getState) => {
  if (id.startsWith('org')) {
    return;
  }

  const { groups, organisations } = getState();

  if (Object.keys(groups?.entities).length === 0) {
    return;
  }

  let organisationId = organisations.selectedOrganisationId;

  if (organisationId === 'ALL_ORGANISATIONS') {
    organisationId = groups.entities[id].organisation_id;
  }

  dispatch(initLoadingGroup({ id }));

  try {
    const { data: props } = await AdminApi.getGroup({ circleId: id, organisationId });
    dispatch(updateGroup({ id, props }));
    return GROUPS_RESPONSES.OK;
  } catch {
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoadingGroup({ id }));
  }
};

export const fetchCircleMembers = circleId => async (dispatch, getState) => {
  if (circleId.startsWith('org')) {
    return;
  }

  const { groups, organisations } = getState();

  if (Object.keys(groups?.entities).length === 0) {
    return;
  }

  let organisationId = organisations.selectedOrganisationId;

  if (!organisationId) {
    return;
  }

  if (organisationId === 'ALL_ORGANISATIONS') {
    organisationId = groups.entities[circleId].organisation_id;
  }

  dispatch(initLoadingGroup({ id: circleId }));

  try {
    const {
      data: { count, results },
    } = await AdminApi.getCircleMembers({
      circleId,
      organisationId,
      filters: groups.circleDetailsFilters,
    });

    dispatch(updateCircleDetailsMembers(results));
    dispatch(updateCircleDetailsMembersCount(count));

    return GROUPS_RESPONSES.OK;
  } catch {
    dispatch(updateCircleDetailsMembers([]));
    dispatch(updateCircleDetailsMembersCount(0));
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoadingGroup({ id: circleId }));
  }
};

export const createGroup = ({ title, organisationId }) => async dispatch => {
  try {
    const { data: group } = await AdminApi.createGroup({ title, organisationId });
    dispatch(updateGroups({ addedGroups: [group] }));
    dispatch(fetchGroups);
    return group.id;
  } catch (errorGroup) {
    throw new Error(errorGroup?.response?.data?.error ?? '');
  }
};

export const deleteGroup = groupId => async dispatch => {
  try {
    await AdminApi.deleteGroup({ id: groupId });
    dispatch(fetchGroups);
    return GROUPS_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const makePublicGroup = id => async dispatch => {
  try {
    const props = {
      is_public: true,
    };
    await AdminApi.updateGroup({ id, props });
    dispatch(updateGroup({ id, props }));
    return GROUPS_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const updateTitleGroup = (id, title) => async dispatch => {
  try {
    const props = {
      title,
    };
    await AdminApi.updateGroup({ id, props });
    dispatch(updateGroup({ id, props }));
    return GROUPS_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const addMembersToGroup = (id, usersIds = []) => async dispatch => {
  if (!id) {
    return false;
  }

  try {
    await AdminApi.addMembersToGroup({ id, usersIds });
    dispatch(updateMembersGroup({ id, usersIds, type: 'ADD' }));

    return GROUPS_RESPONSES.OK;
  } catch (e) {
    return false;
  }
};

export const deleteMemberFromGroup = (id, userId) => async dispatch => {
  try {
    await AdminApi.deleteMemberFromGroup({ id, userId });
    dispatch(updateMembersGroup({ id, usersIds: [userId], type: 'DELETE' }));
    return GROUPS_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const setCanBeFederated = (id, allowFederation) => async dispatch => {
  try {
    const props = {
      allow_federation: allowFederation,
    };
    await AdminApi.updateGroup({ id, props });
    dispatch(updateGroup({ id, props }));
    return GROUPS_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export default groupsSlice.reducer;
