import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';

import { getOrganisationsList } from 'features/organisations/organisationsSlice';
import { getReleaseHash } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';
import cleanString from 'utils/cleanString';

export const ALL_ORGANISATIONS_KEY = 'ALL_ORGANISATIONS';

const useOrganisationsSelectOptions = ({
  showSelectAll,
  showSystemDefault,
  showFederated,
} = {}) => {
  const [t] = useTranslation();
  const organisations = useSelector(getOrganisationsList);
  const releaseHash = useSelector(getReleaseHash);

  return useMemo(() => {
    const itemAll = showSelectAll
      ? [
          {
            key: ALL_ORGANISATIONS_KEY,
            value: ALL_ORGANISATIONS_KEY,
            label: t('all_filter'),
          },
        ]
      : [];
    const systemDefault = showSystemDefault
      ? [
          {
            key: releaseHash?.sys_org_id,
            value: releaseHash?.sys_org_id,
            label: t('SYSTEM_DEFAULT'),
          },
        ]
      : [];
    const organisationsArray = sortBy(
      (organisations || [])
        .map(organisation => ({
          key: organisation.id,
          value: organisation.id,
          label: `${organisation.name} ${
            organisation.federation ? `(${organisation.federation})` : ''
          }`,
          federation: organisation.federation,
        }))
        .filter(
          organisation => !organisation.federation || (organisation.federation && showFederated),
        ),
      item => cleanString(item.label),
    );

    return [...systemDefault, ...itemAll, ...organisationsArray];
  }, [showSelectAll, t, showSystemDefault, releaseHash?.sys_org_id, organisations, showFederated]);
};

export default useOrganisationsSelectOptions;
