import pkg from '../package.json';

const { protocol: localProtocol, port: hostport, hostname } = window.location;
const port = hostport !== '80' ? `:${hostport}` : '';

const server = {
  protocol: localProtocol,
  domain: hostname + port,
  apiVersion: 18,
};

const { protocol, domain, apiVersion } = server;

const baseUrl = `${protocol}//${domain}/v${apiVersion}`;

const config = {
  version: pkg.version,
  server,
  baseUrl,
  apiUrl: `${baseUrl}/admin`,
  apiUrlDrf: `${baseUrl}/drf/admin`,
  MAX_TIME_INACTIVE: 30 * 60, // 30 minutes * 60 seconds
  SECONDS_TIMEOUT: 60,
};

export default config;
