import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import { batch } from 'react-redux';
import { addUnauthorizedInterceptor, removeUnauthorizedInterceptor } from 'utils/axios';
import { showHttpError } from 'features/httpError/httpErrorSlice';

export const LOGIN_RESPONSES = {
  OK: 'ok',
  INVALID_CREDENTIALS: 'invalid_credentials',
  ORGANISATION_DISABLED: 'organisation_disabled',
  TOO_MANY_ATTEMPTS: 'too_many_attempts',
  CODE_NEEDED: 'Code2FA',
  GENERIC_ERROR: 'generic_error',
  EMAIL_NOT_CONFIRMED: 'email_not_confirmed',
  MOBILE_NOT_CONFIRMED: 'mobile_not_confirmed',
  ADMIN_NOT_ENABLED: 'admin_not_enabled',
  LOGIN_ATTEMPT_BLOCKED: 'login_attempt_blocked',
  INVALID_CODE_2FA: 'invalid_code_2fa',
  UNAUTHORIZED: 'unauthorized',
};

export const CONFIRM_MOBILE_PHONE_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ERROR_403: 'error_403',
};

export const CONFIRM_EMAIL_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ERROR_403: 'error_403',
};

export const authInitialState = {
  isLoading: false,
  isAuthenticated: false,
  blockedLoginDate: new Date().toISOString(),
  lastRequest: null,
};

const startLogin = state => {
  state.isLoading = true;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    authRequest: startLogin,
    authFinished: state => {
      state.isLoading = false;
    },
    authSuccess: state => {
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    authFailed: state => {
      state.isLoading = false;
      state.isAuthenticated = false;
    },

    authBlockDate: (state, action) => {
      const newDate = new Date();
      newDate.setSeconds(newDate.getSeconds() + (action.payload || 0));
      state.blockedLoginDate = newDate.toISOString();
    },
    updateLastRequest: state => {
      state.lastRequest = new Date().toISOString();
    },
    resetApp: () => {},
  },
});

export const {
  authRequest,
  authSuccess,
  authFailed,
  authFinished,
  authBlockDate,
  updateLastRequest,
  resetApp,
} = authSlice.actions;

export const doLogin = ({ email, password, code2FA } = {}) => async (dispatch, getState) => {
  const { auth: authState } = getState();
  const { blockedLoginDate } = authState;
  try {
    const now = new Date();
    if (now < blockedLoginDate) {
      // prevent login
      dispatch(authFailed(LOGIN_RESPONSES.LOGIN_ATTEMPT_BLOCKED));
      return LOGIN_RESPONSES.LOGIN_ATTEMPT_BLOCKED;
    }

    dispatch(authRequest());
    await AdminApi.login({ email, password, code2FA });
    addUnauthorizedInterceptor();
    dispatch(authSuccess());
    return LOGIN_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        // Missing parameters
        dispatch(authFailed(LOGIN_RESPONSES.INVALID_CREDENTIALS));
        return LOGIN_RESPONSES.INVALID_CREDENTIALS;
      }

      if (status === 401) {
        // Invalid credentials

        // retry_delay parameter indicates the number of seconds the client has to wait before trying another attempt
        const {
          data: {
            error: { retry_delay },
          },
        } = error.response;

        const response = code2FA
          ? LOGIN_RESPONSES.INVALID_CODE_2FA
          : LOGIN_RESPONSES.INVALID_CREDENTIALS;

        batch(() => {
          dispatch(authFailed(response));
          dispatch(authBlockDate(retry_delay));
        });

        return response;
      }

      if (status === 403) {
        // Forbidden
        // read parameters confirmed_email, confirmed_mobile, enabled.
        const { error: errorData } = error.response.data;
        let result = null;

        if (!errorData?.enabled) {
          result = LOGIN_RESPONSES.ADMIN_NOT_ENABLED;
        }
        if (!errorData?.confirmed_email) {
          result = LOGIN_RESPONSES.EMAIL_NOT_CONFIRMED;
        }
        if (!errorData?.confirmed_mobile) {
          result = LOGIN_RESPONSES.MOBILE_NOT_CONFIRMED;
        }

        if (!errorData) {
          result = LOGIN_RESPONSES.GENERIC_ERROR;
        }

        dispatch(authFailed(result));
        return result;
      }

      if (status === 406) {
        // Need 2FA Code

        dispatch(authFinished());
        return LOGIN_RESPONSES.CODE_NEEDED;
      }

      if (status === 409) {
        // Organisation disabled
        dispatch(authFailed(LOGIN_RESPONSES.ORGANISATION_DISABLED));
        return LOGIN_RESPONSES.ORGANISATION_DISABLED;
      }

      if (status === 429) {
        // Too many attempts
        dispatch(authFailed(LOGIN_RESPONSES.TOO_MANY_ATTEMPTS));
        return LOGIN_RESPONSES.TOO_MANY_ATTEMPTS;
      }
    }
    dispatch(authFailed(LOGIN_RESPONSES.GENERIC_ERROR));
    return LOGIN_RESPONSES.GENERIC_ERROR;
  }
};

export const doLogout = () => async dispatch => {
  try {
    try {
      await AdminApi.logout();
    } catch {
      dispatch(showHttpError());
    }
    removeUnauthorizedInterceptor();
    dispatch(resetApp());
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const doConfirmMobilePhone = ({ email, pin }) => async () => {
  try {
    await AdminApi.confirmMobilePhone({ email, pin });
    return CONFIRM_MOBILE_PHONE_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return CONFIRM_MOBILE_PHONE_RESPONSES.ERROR_403;
      }
    }
    return CONFIRM_MOBILE_PHONE_RESPONSES.ERROR;
  }
};

export const doConfirmEmail = ({ secret }) => async () => {
  try {
    await AdminApi.confirmEmail({ secret });
    return CONFIRM_EMAIL_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return CONFIRM_EMAIL_RESPONSES.ERROR_403;
      }
    }
    return CONFIRM_EMAIL_RESPONSES.ERROR;
  }
};

const getAuthState = state => state.auth;

export const isAuthLoading = createSelector([getAuthState], authState => authState.isLoading);

export const isAuthenticated = createSelector(
  [getAuthState],
  authState => authState.isAuthenticated,
);

export const getLastRequest = createSelector([getAuthState], authState => authState.lastRequest);

export default authSlice.reducer;
