import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';

const ALREADY_REGISTERED = 'already registered';

export const REGISTER_RESPONSES = {
  OK: 'ok',
  GENERIC_ERROR: 'generic_error',
  BAD_REQUEST: 'bad_request',
  CONFLICT: 'conflict',
  ALREADY_REGISTERED: 'already_registered',
  MOBILE_NOT_VALID: 'mobile_not_valid',
  PHONE_NUMBER_NOT_VALID: 'phone_number_not_valid',
};

export const registerInitialState = {
  isLoading: false,
  isRegistered: false,
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState: registerInitialState,
  reducers: {
    registerRequest: state => {
      state.isLoading = true;
    },
    registerFinished: state => {
      state.isLoading = false;
    },
    registerSuccess: state => {
      state.isRegistered = true;
      state.isLoading = false;
    },
    registerFailed: (state, action) => {
      state.isLoading = false;
      state.isRegistered = false;
      state.error = action.payload || REGISTER_RESPONSES.GENERIC_ERROR;
    },
  },
});

export const {
  registerRequest,
  registerSuccess,
  registerFailed,
  registerFinished,
} = registerSlice.actions;

export const doRegister = (values = {}) => async (dispatch, getState) => {
  try {
    dispatch(registerRequest());
    await AdminApi.register(values);
    dispatch(registerSuccess());

    return REGISTER_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        if (error.response.data.error === ALREADY_REGISTERED) {
          dispatch(registerFailed(REGISTER_RESPONSES.ALREADY_REGISTERED));
          return REGISTER_RESPONSES.ALREADY_REGISTERED;
        } else {
          dispatch(registerFailed(REGISTER_RESPONSES.BAD_REQUEST));

          if (
            [
              'Parameter is not a valid phone number: mobile',
              'Parameter is not a valid mobile phone number: mobile',
            ].includes(error.response.data.error)
          ) {
            return REGISTER_RESPONSES.MOBILE_NOT_VALID;
          }

          if (error.response.data.error === 'Parameter is not a valid phone number: phone') {
            return REGISTER_RESPONSES.PHONE_NUMBER_NOT_VALID;
          }

          return REGISTER_RESPONSES.BAD_REQUEST;
        }
      }

      if (status === 409) {
        dispatch(registerFailed(REGISTER_RESPONSES.CONFLICT));
        return REGISTER_RESPONSES.CONFLICT;
      }
    }
    dispatch(registerFailed(REGISTER_RESPONSES.GENERIC_ERROR));
    return REGISTER_RESPONSES.GENERIC_ERROR;
  }
};

const getRegisterState = state => state.register;

export const getRegisterLoading = createSelector(
  [getRegisterState],
  registerState => registerState.isLoading,
);
export const getRegistered = createSelector(
  [getRegisterState],
  registerState => registerState.isRegistered,
);
export const getRegisterError = createSelector(
  [getRegisterState],
  registerState => registerState.error,
);

export default registerSlice.reducer;
