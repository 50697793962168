import { SvgIcon } from '@material-ui/core';

const Delete = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path d="M9.16667 7.50033V15.0003H7.5V7.50033H9.16667Z" />
    <path d="M12.5 15.0003V7.50033H10.8333V15.0003H12.5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1667 4.16699C14.1667 2.78628 13.0474 1.66699 11.6667 1.66699H8.33333C6.95262 1.66699 5.83333 2.78628 5.83333 4.16699H2.5V5.83366H4.16667V16.667C4.16667 17.5875 4.91286 18.3337 5.83333 18.3337H14.1667C15.0871 18.3337 15.8333 17.5875 15.8333 16.667V5.83366H17.5V4.16699H14.1667ZM8.33333 3.33366C7.8731 3.33366 7.5 3.70675 7.5 4.16699H12.5C12.5 3.70675 12.1269 3.33366 11.6667 3.33366H8.33333ZM14.1667 5.83366H5.83333V16.667H14.1667V5.83366Z"
    />
  </SvgIcon>
);

export default Delete;
