import colors from 'theme/colors';

const MuiCheckbox = {
  root: {
    color: colors.darkGrey,
    '&:hover': {
      background: 'none',
    },
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: colors.darkBlue,
        zIndex: -1,
      },
    },
    '&.MuiCheckbox-colorSecondary.Mui-disabled': {
      color: colors.mediumGrey,
    },
  },
};
export default MuiCheckbox;
