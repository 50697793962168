import { useState, useEffect, useCallback, Suspense } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { HotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { isAuthenticated } from 'features/auth/authSlice';
import {
  fetchProfile,
  PROFILE_RESPONSES,
  getIsProfileFetched,
} from 'features/profile/profileSlice';
import {
  fetchAllOrganisations,
  setSelectedOrganisation,
  ORGANISATIONS_RESPONSES,
} from 'features/organisations/organisationsSlice';

import TopBar from 'containers/TopBar';
import NavBar from 'containers/NavBar';
import TimeoutCountdown from 'containers/TimeoutCountdown';

import { CenterBoxLoading } from 'components/Loading';
import usePrevious from 'hooks/usePrevious';
import usePermissions from 'hooks/usePermissions';

const mapTranslations = {
  [PROFILE_RESPONSES.NOT_FOUND]: 'PROFILE_NOT_FOUND',
  [PROFILE_RESPONSES.FORBIDDEN]: 'PROFILE_FORBIDDEN',
  [PROFILE_RESPONSES.CONFLICT]: 'PROFILE_CONFLICT',
};

const mapTranslationsOrganisations = {
  [ORGANISATIONS_RESPONSES.FORBIDDEN]: 'ORGANISATION_FORBIDDEN',
  [ORGANISATIONS_RESPONSES.NOT_FOUND]: 'ORGANISATION_NOT_FOUND',
  [ORGANISATIONS_RESPONSES.ERROR]: 'ORGANISATION_ERROR',
};

const getPermissionByPath = pathname => {
  let permissionKey = null;

  if (pathname.includes('/audit-log')) {
    permissionKey = 'SECTION_AUDIT_LOG';
  }

  if (pathname.includes('/users')) {
    permissionKey = 'SECTION_USERS';
  }

  if (pathname.includes('/circles')) {
    permissionKey = 'SECTION_GROUPS';
  }

  if (pathname.includes('/integrations')) {
    permissionKey = 'SECTION_APP_INTEGRATIONS';
  }

  if (pathname.includes('/admins')) {
    permissionKey = 'SECTION_ADMINS';
  }

  if (pathname.includes('/domains')) {
    permissionKey = 'SECTION_DOMAINS';
  }

  if (pathname.includes('/settings/privacy')) {
    permissionKey = 'SECTION_PRIVACY_SETTINGS';
  }
  if (pathname.includes('/settings/retention')) {
    permissionKey = 'SECTION_RETENTION_SETTINGS';
  }
  if (pathname.includes('/settings/login-registration')) {
    permissionKey = 'SECTION_LOGIN_REGISTRATION';
  }
  if (pathname.includes('/settings/permissions')) {
    permissionKey = 'APP_PERMISSIONS';
  }
  if (pathname.includes('/settings/style')) {
    permissionKey = 'STYLE_SETTINGS';
  }
  if (pathname.includes('/settings/email')) {
    permissionKey = 'EMAIL_TEMPLATES';
  }
  if (pathname.includes('/settings/ldap')) {
    permissionKey = 'LDAP_INTEGRATION';
  }

  if (pathname.includes('/organisations')) {
    permissionKey = 'SECTION_ORGANISATIONS';
  }

  if (pathname.includes('/settings/archives')) {
    permissionKey = 'ARCHIVES';
  }
  return permissionKey;
};
const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
    background: '#f4f6f8',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 64,
    paddingBottom: 0,
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    transition: 'padding-left 0.3s ease',
  },
  contentWithDrawer: {
    paddingLeft: 256,
  },
}));

const LoggedContent = ({ route }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthenticated);
  const isProfileFetched = useSelector(getIsProfileFetched);
  const { iAmSuperAdmin } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const isResponsive = useMediaQuery(theme.breakpoints.down('md'));
  const previousIsResponsive = usePrevious(isResponsive);
  const [openNavBar, setOpenNavBar] = useState(!isResponsive);

  useEffect(() => {
    if (previousIsResponsive && !isResponsive) {
      setOpenNavBar(true);
    }

    if (!previousIsResponsive && isResponsive) {
      setOpenNavBar(false);
    }
  }, [isResponsive, openNavBar, previousIsResponsive]);

  const handleToggle = useCallback(() => {
    setOpenNavBar(prev => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpenNavBar(false);
  }, []);

  const handleOnClick = useCallback(() => {
    if (openNavBar && isResponsive) {
      setOpenNavBar(false);
    }
  }, [isResponsive, openNavBar]);

  useEffect(() => {
    const syncData = async () => {
      const resultProfile = await dispatch(fetchProfile);
      if (Object.keys(mapTranslations).includes(resultProfile)) {
        enqueueSnackbar(t(mapTranslations[resultProfile]), { variant: 'error' });
      } else {
        dispatch(setSelectedOrganisation({ organisationId: resultProfile.organisation_id }));
      }
      const resultOrganisation = await dispatch(fetchAllOrganisations);
      if (Object.keys(mapTranslationsOrganisations).includes(resultOrganisation)) {
        enqueueSnackbar(t(mapTranslationsOrganisations[resultOrganisation]), {
          variant: 'error',
        });
      }
    };
    if (isAuth) {
      syncData();
    }
    // eslint-disable-next-line
  }, [dispatch, isAuth, enqueueSnackbar]);

  const handlers = {
    TOOGLE_MENU: handleToggle,
    GO_TO_ADMINS: () => {
      history.push('/admins');
    },
    GO_TO_HOME: () => {
      history.push('/overview');
    },
    GO_TO_AUDITLOG: () => {
      if (iAmSuperAdmin) {
        history.push('/audit-log');
      }
    },
    GO_TO_USERS: () => {
      history.push('/users');
    },
    GO_TO_CIRCLES: () => {
      history.push('/circles');
    },
    GO_TO_DOMAINS: () => {
      history.push('/domains');
    },
    GO_TO_ORGANISATIONS: () => {
      if (iAmSuperAdmin) {
        history.push('/organisations');
      }
    },
    GO_TO_INTEGRATIONS: () => {
      history.push('/integrations');
    },
    GO_TO_SETTINGS: () => {
      history.push('/settings/privacy');
    },
    GO_TO_PROFILE: () => {
      history.push('/profile');
    },
  };

  const { canView } = usePermissions();
  const { pathname } = location;

  if (!isAuth) {
    return <Redirect to={{ pathname: '/auth/login', state: { from: location } }} />;
  }

  if (!isProfileFetched) {
    return (
      <Box height="100vh" display="flex">
        <CenterBoxLoading />
      </Box>
    );
  }

  const permissionKey = getPermissionByPath(pathname);
  if (permissionKey && !canView(permissionKey)) {
    enqueueSnackbar(t('NOT_ALLOW_VIEW'), { variant: 'error' });
    return <Redirect to="/overview" />;
  }

  return (
    <HotKeys handlers={handlers}>
      <TopBar onToggleNavBar={handleToggle} drawerOpened={openNavBar} />
      <NavBar
        onClose={handleClose}
        open={openNavBar}
        isResponsive={isResponsive}
        onClick={handleOnClick}
      />
      <div className={classes.container}>
        <div
          className={clsx(classes.content, {
            [classes.contentWithDrawer]: !isResponsive & openNavBar,
          })}
        >
          <Suspense fallback={<CenterBoxLoading />}>{renderRoutes(route.routes)}</Suspense>
        </div>
      </div>
      <TimeoutCountdown />
    </HotKeys>
  );
};

LoggedContent.propTypes = {};

export default LoggedContent;
