import axios from 'axios';
import config from 'config';
import store from 'store/configureStore';
import { resetApp, updateLastRequest } from 'features/auth/authSlice';

const { baseUrl } = config;

const instance = axios.create({
  baseURL: `${baseUrl}/admin`,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

let unauthorizedInterceptor = null;
export const addUnauthorizedInterceptor = () => {
  unauthorizedInterceptor = instance.interceptors.response.use(
    response => response,
    error => {
      const { url } = error.response?.config || {};
      if (error.response?.status === 401 && url !== '/password') {
        store.dispatch(resetApp());
      }
      return Promise.reject(error);
    },
  );
};

export const removeUnauthorizedInterceptor = () => {
  instance.interceptors.response.eject(unauthorizedInterceptor);
};

let lastRequestInterceptor = null;
export const addLastRequestInterceptor = () => {
  lastRequestInterceptor = instance.interceptors.request.use(
    response => {
      store.dispatch(updateLastRequest());
      return Promise.resolve(response);
    },
    error => {
      store.dispatch(updateLastRequest());
      return Promise.reject(error);
    },
  );
};
export const removeLastRequestInterceptor = () => {
  instance.interceptors.request.rejest(lastRequestInterceptor);
};

addLastRequestInterceptor();

export default instance;
