import { renderRoutes } from 'react-router-config';
import { HotKeys } from 'react-hotkeys';

import { CssBaseline } from '@material-ui/core';

import { StoreProvider } from 'contexts/StoreContext';
import { ThemeProvider } from 'contexts/ThemeContext';
import { RouterProvider } from 'contexts/RouterContext';
import { NotificationsProvider } from 'contexts/NotificationsContext';

import GenericError from 'views/GenericError';

import ErrorBoundary from 'components/ErrorBoundary';
import { HttpErrorMonitor } from 'components/HttpErrorMonitor';

import GlobalCss from 'theme/globalCss';

import 'mixins/chartjs';
import 'typeface-poppins';
import 'typeface-roboto';

import routes from 'routes';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const keyMap = {
  TOOGLE_MENU: 'gm',

  GO_TO_HOME: 'gh',
  GO_TO_AUDITLOG: 'gl',
  GO_TO_ADMINS: 'ga',
  GO_TO_USERS: 'gu',
  GO_TO_CIRCLES: 'gc',
  GO_TO_DOMAINS: 'gd',
  GO_TO_ORGANISATIONS: 'go',
  GO_TO_INTEGRATIONS: 'gi',
  GO_TO_SETTINGS: 'gs',

  GO_TO_PROFILE: 'gp',
};

const App = () => (
  <ThemeProvider>
    <GlobalCss />
    <HotKeys keyMap={keyMap}>
      <ErrorBoundary fallback={<GenericError />}>
        <StoreProvider>
          <CssBaseline />
          <NotificationsProvider>
            <HttpErrorMonitor />
            <QueryClientProvider client={queryClient}>
              <RouterProvider>{renderRoutes(routes)}</RouterProvider>
            </QueryClientProvider>
          </NotificationsProvider>
        </StoreProvider>
      </ErrorBoundary>
    </HotKeys>
  </ThemeProvider>
);

export default App;
