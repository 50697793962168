import { Router } from 'react-router-dom';
import { createHashHistory } from 'history';
import ScrollToTop from 'components/ScrollToTop';

export const history = createHashHistory();

export const RouterProvider = ({ children }) => (
  <Router history={history}>
    <ScrollToTop />
    {children}
  </Router>
);
