import { combineReducers } from '@reduxjs/toolkit';

import auth, { resetApp } from 'features/auth/authSlice';
import profile from 'features/profile/profileSlice';
import users from 'features/users/usersSlice';
import register from 'features/register/registerSlice';
import recover2FA from 'features/recover2FA/recover2FASlice';
import recover from 'features/recover/recoverSlice';
import reset from 'features/reset/resetSlice';
import dashboard from 'features/dashboard/dashboardSlice';
import groups from 'features/groups/groupsSlice';
import organisations from 'features/organisations/organisationsSlice';
import admins from 'features/admins/adminsSlice';
import domains from 'features/domains/domainsSlice';
import integrations from 'features/integrations/integrationsSlice';
import settings from 'features/settings/settingsSlice';
import httpError from 'features/httpError/httpErrorSlice';
import generalSettings from 'features/generalSettings/generalSettingsSlice';
import divisions from 'features/divisions/divisionsSlice';

const appReducer = combineReducers({
  auth,
  profile,
  users,
  groups,
  register,
  recover2FA,
  recover,
  reset,
  dashboard,
  organisations,
  admins,
  domains,
  integrations,
  settings,
  httpError,
  generalSettings,
  divisions,
});

const resetAppAction = resetApp?.toString() || 'auth/resetApp';
const rootReducer = (state, action) => {
  if (action.type === resetAppAction) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
