import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import sortComparerBy from 'utils/sortComparerBy';
import { showHttpError } from 'features/httpError/httpErrorSlice';
import moment from 'moment';

export const ADMIN_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
};

/*
{
  "two_factor_enabled": false,
  "email_hash": "88d82037ac89d38d279f21859b10fcd725ead16396d64f7f8dc9046bfd3dc865",
  "last_name": "TW-Demo",
  "division": "Testing",
  "company": "Teamwire-demo",
  "city": "a city",
  "phone": "+34665399916",
  "postcode": "03800",
  "address": "Sesame Str",
  "read_only": false,
  "organisation_id": 134,
  "first_name": "Clara",
  "preferred_language": "En",
  "mobile": "+34665399916",
  "country": "Spain",
  "created_at": "2017-04-29T10:13:23Z",
  "enabled": true,
  "last_login": 1583247578,
  "role": "Beta-tester",
  "super_admin": false,
  "email": "clara@teamwire-demo.de"
}
*/

const adminsAdapter = createEntityAdapter({
  selectId: admin => admin.email_hash,
  sortComparer: sortComparerBy('email_hash'),
});

export const initialFilters = {
  enabled: null,
  limit: 10,
  offset: 0,
  ordering: 'last_name',
  search: null,
  super_admin: null,
  two_factor_enabled: null,
};

const AdminsSlice = createSlice({
  name: 'admins',
  initialState: adminsAdapter.getInitialState({
    isLoading: false,
    isDownloadingCsvData: false,
    lastUpdated: null,
    adminsLoading: [],
    permissionsByEmailHash: {},
    count: 0,
    filters: initialFilters,
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    initCsvDownload: state => {
      state.isDownloadingCsvData = true;
    },
    finishCsvDownload: state => {
      state.isDownloadingCsvData = false;
    },
    initLoadingAdmin: (state, action) => {
      const { emailHash } = action.payload;
      if (!state.adminsLoading.includes(emailHash)) {
        state.adminsLoading.push(emailHash);
      }
    },
    finishLoadingAdmin: (state, action) => {
      const { emailHash } = action.payload;
      state.adminsLoading = state.adminsLoading.filter(adminLoading => adminLoading !== emailHash);
    },
    updateAdmins: (state, action) => {
      const { admins = [], deletedAdminsEmailHashes = [] } = action.payload;

      state.lastUpdated = new Date().toUTCString();

      adminsAdapter.setAll(state, admins);
      adminsAdapter.removeMany(state, deletedAdminsEmailHashes);
    },
    updateAdmin: (state, action) => {
      const { emailHash, props = {}, propsPermissions = {} } = action.payload;

      adminsAdapter.upsertOne(state, { email_hash: emailHash, ...props });

      state.permissionsByEmailHash[emailHash] = {
        ...(state.permissionsByEmailHash[emailHash] || {}),
        ...propsPermissions,
      };
    },
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const {
  initLoading,
  finishLoading,
  initCsvDownload,
  finishCsvDownload,
  initLoadingAdmin,
  finishLoadingAdmin,
  updateAdmins,
  updateAdmin,
  updateCount,
  updateFilters,
} = AdminsSlice.actions;

// SELECTORS
const getAdminsState = state => state.admins;
export const adminsGlobalSelector = adminsAdapter.getSelectors(getAdminsState);
export const getAdminsLastUpdated = createSelector([getAdminsState], state => state.lastUpdated);

export const getIsAdminsLoading = createSelector([getAdminsState], state => state.isLoading);
export const getIsDownloadingCsvData = createSelector(
  [getAdminsState],
  state => state.isDownloadingCsvData,
);

export const getAdminsList = adminsGlobalSelector.selectAll;

export const getAdminsListByOrganisation = createSelector(
  [getAdminsList, (_, organisationId) => parseInt(organisationId)],
  (admins, organisationId) => {
    let list = admins;
    if (organisationId) {
      list = admins.filter(admin => admin.organisation_id === organisationId);
    }
    return list.map(o => ({ ...o }));
  },
);

export const getAdminByHash = adminsGlobalSelector.selectById;

export const getPermissionByHash = createSelector(
  state => state.admins.permissionsByEmailHash,
  (_, adminHash) => adminHash,
  (permissionsByEmailHash, adminHash) => permissionsByEmailHash[adminHash],
);

export const getAdminsCount = state => state.admins.count;

export const getFilters = state => state.admins.filters;

// THUNKS

export const getAdminsCsvData = async (dispatch, getState) => {
  try {
    dispatch(initCsvDownload());

    const {
      organisations,
      admins: { filters },
    } = getState();

    let csvAdmins;

    const organisationId = organisations.selectedOrganisationId;

    const { data } = await AdminApi.getAllAdmins();

    if (organisationId === 'ALL_ORGANISATIONS') {
      csvAdmins = data;
    } else {
      csvAdmins = data.filter(admin => admin.organisation_id === organisationId);
    }

    csvAdmins = csvAdmins
      .filter(admin => {
        if (filters.super_admin !== null && JSON.parse(filters.super_admin) !== admin.super_admin) {
          return false;
        }

        if (
          filters.two_factor_enabled !== null &&
          JSON.parse(filters.two_factor_enabled) !== admin.two_factor_enabled
        ) {
          return false;
        }

        if (filters.enabled !== null && JSON.parse(filters.enabled) !== admin.enabled) {
          return false;
        }

        if (filters.search !== null) {
          const lowercaseSearch = filters.search.toLowerCase();

          if (
            !admin.first_name?.toLowerCase().includes(lowercaseSearch) &&
            !admin.last_name?.toLowerCase().includes(lowercaseSearch) &&
            !admin.email?.toLowerCase().includes(lowercaseSearch)
          ) {
            return false;
          }
        }

        return true;
      })
      .sort((adminA, adminB) => {
        let sortFactor = 1;
        if (filters.ordering.startsWith('-')) {
          sortFactor = -1;
        }

        if (filters.ordering.includes('first_name')) {
          return (
            adminA.first_name.toUpperCase().localeCompare(adminB.first_name.toUpperCase()) *
            sortFactor
          );
        }

        if (filters.ordering.includes('last_name')) {
          return (
            adminA.last_name?.toUpperCase().localeCompare(adminB.last_name?.toUpperCase()) *
            sortFactor
          );
        }

        if (filters.ordering.includes('email')) {
          return adminA.email.toUpperCase().localeCompare(adminB.email.toUpperCase()) * sortFactor;
        }

        if (filters.ordering.includes('created_at')) {
          return moment(adminA.created_at).isAfter(moment(adminB.created_at)) * sortFactor;
        }

        if (filters.ordering.includes('last_login')) {
          return moment(adminA.last_login).isAfter(moment(adminB.last_login)) * sortFactor;
        }

        if (filters.ordering.includes('super_admin')) {
          return (
            adminA.super_admin
              .toString()
              .toUpperCase()
              .localeCompare(adminB.super_admin.toString().toUpperCase()) * sortFactor
          );
        }

        if (filters.ordering.includes('two_factor_enabled')) {
          return (
            adminA.two_factor_enabled
              .toString()
              .toUpperCase()
              .localeCompare(adminB.two_factor_enabled.toString().toUpperCase()) * sortFactor
          );
        }

        if (filters.ordering.includes('enabled')) {
          return (
            adminA.enabled
              .toString()
              .toUpperCase()
              .localeCompare(adminB.enabled.toString().toUpperCase()) * sortFactor
          );
        }

        return 0;
      });

    return csvAdmins;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishCsvDownload());
  }
};

export const fetchAdmins = async (dispatch, getState) => {
  const { admins, organisations, profile } = getState();

  let organisationId =
    organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
      ? 'all'
      : organisations.selectedOrganisationId;

  if (organisationId === null) {
    organisationId = profile.data.organisation_id;
  }

  dispatch(initLoading());
  try {
    const {
      data: { count, results },
    } = await AdminApi.getPaginatedAdmins({ organisationId, filters: admins.filters });
    dispatch(updateAdmins({ admins: results }));
    dispatch(updateCount(count));
    return ADMIN_RESPONSES.OK;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoading());
  }
};

export const fetchAdmin = emailHash => async dispatch => {
  dispatch(initLoadingAdmin({ emailHash }));
  try {
    const { data: adminData } = await AdminApi.getAdmin({ emailHash });
    const { data: permissions } = await AdminApi.getAdminPermissions(emailHash);
    const props = {
      ...adminData,
    };
    const propsPermissions = {
      ...permissions,
    };
    dispatch(updateAdmin({ emailHash, props, propsPermissions }));
    return ADMIN_RESPONSES.OK;
  } catch (e) {
    dispatch(finishLoadingAdmin({ emailHash }));
    throw ADMIN_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoadingAdmin({ emailHash }));
  }
};

export const deleteAdmin = ({ emailHash }) => async dispatch => {
  try {
    await AdminApi.deleteAdmin({ emailHash });
    dispatch(updateAdmins({ deletedAdminsEmailHashes: [emailHash] }));
    return ADMIN_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const disableTwoFactorAuth = ({ emailHash }) => async dispatch => {
  try {
    await AdminApi.disableTwoFactorAuth({ emailHash });
    const props = {
      two_factor_enabled: false,
    };
    dispatch(updateAdmin({ emailHash, props }));
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const updateEnableAdmin = (emailHash, enabled) => async dispatch => {
  const props = {
    enabled,
  };
  try {
    await AdminApi.updateAdmin({ emailHash, props });
    dispatch(updateAdmin({ emailHash, props }));
    return ADMIN_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const saveAdmin = (emailHash, adminProps, permissionProps = null) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const admin = getAdminByHash(state, emailHash);
  const props = {
    ...admin,
    ...adminProps,
  };
  try {
    const { data: newData } = await AdminApi.updateAdmin({ emailHash, props });
    if (permissionProps) {
      const { data: newPermissions } = await AdminApi.updateAdminPermissions({
        emailHash,
        permissions: permissionProps,
      });
      dispatch(updateAdmin({ emailHash, props: newData, propsPermissions: newPermissions }));
    } else {
      dispatch(updateAdmin({ emailHash, props: newData }));
    }
    return ADMIN_RESPONSES.OK;
  } catch (e) {
    dispatch(showHttpError());
  }
};

export const createAdmin = props => async dispatch => {
  const {
    first_name,
    last_name,
    email,
    mobile,
    phone,
    company,
    role,
    division,
    city,
    postcode,
    address,
    country,
    super_admin = false,
  } = props;
  try {
    const { data: admin } = await AdminApi.createAdmin({
      first_name,
      last_name,
      email,
      mobile: mobile.replace(/\s/g, '').replace('+', ''),
      phone: phone.replace(/\s/g, '').replace('+', ''),
      company,
      role,
      division,
      city,
      postcode,
      address,
      country,
      super_admin,
    });

    const adminObject = { ...props, email_hash: admin.email_hash };
    dispatch(updateAdmins({ admins: [adminObject] }));

    return admin?.email_hash;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      throw data.error;
    }
    throw ADMIN_RESPONSES.ERROR;
  }
};

export default AdminsSlice.reducer;
