import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  showError: false,
  errorMessage: undefined,
};

const httpErrorSlice = createSlice({
  name: 'httpError',
  initialState,
  reducers: {
    showHttpError(state, action) {
      state.showError = true;
      state.errorMessage = action.payload;
    },
    resetHttpError(state) {
      state.showError = false;
      state.errorMessage = undefined;
    },
  },
});

export const { resetHttpError, showHttpError } = httpErrorSlice.actions;

export const getShowError = createSelector(
  state => state.httpError,
  state => state.showError,
);

export const getErrorMessage = createSelector(
  state => state.httpError,
  state => state.errorMessage,
);

export default httpErrorSlice.reducer;
