import palette from '../palette';

const MuiOutlinedInput = {
  root: {
    borderWidth: '1px',
    '&:hover $notchedOutline': {
      borderColor: palette.primary.main,
    },
    '&$focused $notchedOutline': {
      borderWidth: 1,
    },
  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)',
  },
};

export default MuiOutlinedInput;
