import colors from 'theme/colors';

const MuiTooltip = {
  tooltip: {
    backgroundColor: colors.darkGrey,
    borderRadius: 4,
    boxShadow: '0 0 33px 0 rgba(0, 0, 0, 0.25)',
    fontFamily: '"Roboto"',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.33rem',
    letterSpacing: '0.36px',
    color: colors.white,
    padding: '8px 12px',
  },
};

export default MuiTooltip;
