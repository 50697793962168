import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getProfile } from 'features/profile/profileSlice';

import NavBarUserInfo from 'components/NavBarUserInfo';

const NavBarUserInfoContainer = ({ userData }) => {
  const profile = useSelector(getProfile);
  const userInfoData = userData || profile;

  const name = useMemo(() => {
    if (!userInfoData) {
      return '';
    }

    return `${userInfoData.first_name} ${userInfoData.last_name}`;
  }, [userInfoData]);

  return (
    <NavBarUserInfo
      isLoading={!userInfoData}
      name={name}
      role={userInfoData?.role}
      isSuperAdmin={userInfoData?.super_admin}
    />
  );
};

NavBarUserInfoContainer.propTypes = {
  userData: PropTypes.object,
};

NavBarUserInfoContainer.defaultProps = {
  userData: null,
};

export default memo(NavBarUserInfoContainer);
