import { Grid, Typography, Button } from '@material-ui/core';

const HandleError = ({ withReload }) => {
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <Grid container direction="column" alignItems="center" justify="center" spacing={3}>
      <Grid item>
        <img alt="generic error" src={process.env.PUBLIC_URL + '/images/generic-error.svg'} />
      </Grid>
      <Grid item>
        <Typography variant="h1">Something went wrong</Typography>
      </Grid>
      {withReload ? (
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleReload}>
            Reload
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default HandleError;
