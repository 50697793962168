import { SvgIcon } from '@material-ui/core';

const EditList = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4692 1.33018C16.8063 0.667283 15.7315 0.667283 15.0686 1.33018L6.66667 9.73216V13.333H10.2675L18.6695 4.93103C19.3324 4.26813 19.3324 3.19336 18.6695 2.53046L17.4692 1.33018ZM17.4692 3.73075L16.2689 2.53046L14.4685 4.33089L15.6688 5.53117L17.4692 3.73075ZM13.2682 5.53117L14.4685 6.73145L9.5644 11.6356H8.36412V10.4353L13.2682 5.53117Z"
    />
    <path d="M5.83333 2.49967H11.6667L10 4.16634H5.83333C4.91286 4.16634 4.16667 4.91253 4.16667 5.83301V14.1663C4.16667 15.0868 4.91286 15.833 5.83333 15.833H14.1667C15.0871 15.833 15.8333 15.0868 15.8333 14.1663V9.99967L17.5 8.33301V14.1663C17.5 16.0073 16.0076 17.4997 14.1667 17.4997H5.83333C3.99238 17.4997 2.5 16.0073 2.5 14.1663V5.83301C2.5 3.99206 3.99238 2.49967 5.83333 2.49967Z" />
  </SvgIcon>
);

export default EditList;
