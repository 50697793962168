import colors from 'theme/colors';

const MuiTableSortLabel = {
  root: {
    // fontFamily: '"Roboto"',
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    fontWeight: '500',
    color: colors.darkGrey,
    '&$active': {
      color: colors.darkBlue,
      '& $icon': {
        color: colors.darkBlue + ' !important',
      },
    },
  },
  icon: {
    color: 'currentColor',
  },
  iconDirectionDesc: {
    transform: 'none',
  },
  iconDirectionAsc: {
    transform: 'none',
  },
};

export default MuiTableSortLabel;
