import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link as RouterLink, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import { Container, makeStyles, Typography, Link } from '@material-ui/core';

import { isAuthenticated } from 'features/auth/authSlice';

import { CenterBoxLoading } from 'components/Loading';
import AuthHeader from 'components/AuthHeader';
import AuthLoading from 'containers/AuthLoading';
import { useTranslation } from 'react-i18next';
import colors from 'theme/colors';

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '100vh',
    paddingTop: 56,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(35),
  },
  subtitle: {
    color: colors.darkGrey,
    textTransform: 'none',
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(7.5, 0),
  },
  footer: {
    margin: theme.spacing(7.5, 0),
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'normal',
  },
}));

const Auth = ({ route }) => {
  const [t] = useTranslation();
  const isAuth = useSelector(isAuthenticated);
  const classes = useStyles();
  const location = useLocation();

  if (isAuth) {
    const { from } = location.state || { from: { pathname: '/overview' } };
    return <Redirect to={from} />;
  }

  return (
    <>
      <AuthLoading />
      <AuthHeader />
      <main className={classes.main}>
        <div className={classes.subheader}>
          <Typography variant="h2" className={classes.title}>
            Teamwire Dashboard
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {t('TEAMWIRE_SUBTITLE')}
          </Typography>
        </div>
        <Container maxWidth="sm">
          <Suspense fallback={<CenterBoxLoading boxProps={{ width: 1 }} />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </Container>
        <div className={classes.footer}>
          <Link component={RouterLink} className={classes.link} to={'/'}>
            Teamwire
          </Link>
        </div>
      </main>
    </>
  );
};

Auth.propTypes = {};

export default Auth;
