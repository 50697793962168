import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import props from './props';

export const baseTheme = {
  palette,
  typography: {
    ...typography,
    poppins: {
      fontFamily: '"Poppins"',
    },
  },
  overrides,
  props,
};

const theme = createMuiTheme(baseTheme);

export default theme;
