import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import { showHttpError } from 'features/httpError/httpErrorSlice';

export const DOMAINS_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ERROR_DOMAIN_EXISTS: 'error_domain_exists',
};

/*
DOMAIN OBJECT:

  organisation_id: 5
  domain: "gmail.com"
  users: 7
  organisation: "gmail.com"
  org_domain_count: 1
  admins: 1
  identifier: "10"
  created_at: 1478533497


  admin_count:38
  domain:"teamwire.eu"
  id:19
  user_count:78
*/

const domainsAdapter = createEntityAdapter({
  selectId: domain => domain.id,
});

export const initialFilters = {
  admins_max: null,
  admins_min: null,
  limit: 10,
  offset: 0,
  ordering: 'domain',
  search: null,
  users_max: null,
  users_min: null,
};

const domainsSlice = createSlice({
  name: 'domains',
  initialState: domainsAdapter.getInitialState({
    isLoading: false,
    isDownloadingCsvData: false,
    lastUpdated: null,
    filters: initialFilters,
    adminCount: {},
    userCount: {},
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    initCsvDownload: state => {
      state.isDownloadingCsvData = true;
    },
    finishCsvDownload: state => {
      state.isDownloadingCsvData = false;
    },
    updateDomains: (state, action) => {
      const { addedDomains = [], deletedDomainsKeys = [] } = action.payload;

      state.lastUpdated = new Date().toUTCString();

      domainsAdapter.setAll(state, addedDomains);
      domainsAdapter.removeMany(state, deletedDomainsKeys);
    },
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateMinMax: (state, action) => {
      const { min_admin_count, max_admin_count, min_user_count, max_user_count } = action.payload;

      state.adminCount = {
        min: min_admin_count,
        max: max_admin_count,
      };

      state.userCount = {
        min: min_user_count,
        max: max_user_count,
      };
    },
  },
});

export const {
  initLoading,
  finishLoading,
  initCsvDownload,
  finishCsvDownload,
  updateDomains,
  updateCount,
  updateFilters,
  updateMinMax,
} = domainsSlice.actions;

// SELECTORS
const getDomainsState = state => state.domains;
export const domainsGlobalSelector = domainsAdapter.getSelectors(getDomainsState);
export const getDomainsLastUpdated = createSelector([getDomainsState], state => state.lastUpdated);
export const getIsDomainsLoading = createSelector([getDomainsState], state => state.isLoading);
export const getIsDownloadingCsvData = createSelector(
  [getDomainsState],
  state => state.isDownloadingCsvData,
);
export const getDomainsList = domainsGlobalSelector.selectAll;

export const getDomainsListByOrganisation = createSelector(
  [domainsGlobalSelector.selectAll, (_, organisationId) => parseInt(organisationId)],
  (domains, organisationId) => {
    let list = domains;
    if (organisationId) {
      list = domains.filter(group => group.organisation_id === organisationId);
    }
    return list.map(o => ({ ...o }));
  },
);

export const getDomainById = domainsGlobalSelector.selectById;

export const getDomainsCount = state => state.domains.count;

export const getFilters = state => state.domains.filters;

export const getAdminCount = state => state.domains.adminCount;

export const getUserCount = state => state.domains.userCount;

// THUNKS
export const getDomainsCsvData = async (dispatch, getState) => {
  try {
    dispatch(initCsvDownload());

    const {
      organisations,
      domains: { filters },
    } = getState();

    let csvDomains;

    const organisationId = organisations.selectedOrganisationId;

    const { data } = await AdminApi.getAllDomains();

    if (organisationId === 'ALL_ORGANISATIONS') {
      csvDomains = data;
    } else {
      csvDomains = data.filter(domain => domain.organisation_id === organisationId);
    }

    csvDomains = csvDomains.map(domain => ({
      ...domain,
      user_count: domain.users,
      admin_count: domain.admins,
    }));

    return csvDomains
      .filter(domain => {
        if (
          filters.admins_min !== null &&
          filters.admins_max !== null &&
          (domain.admin_count < filters.admins_min || domain.admin_count > filters.admins_max)
        ) {
          return false;
        }

        if (
          filters.users_min !== null &&
          filters.users_max !== null &&
          (domain.user_count < filters.users_min || domain.user_count > filters.users_max)
        ) {
          return false;
        }

        return !(
          filters.search !== null &&
          !domain.domain.toLowerCase().includes(filters.search.toLowerCase())
        );
      })
      .sort((domainA, domainB) => {
        let sortFactor = 1;
        if (filters.ordering.startsWith('-')) {
          sortFactor = -1;
        }

        if (filters.ordering.includes('domain')) {
          return (
            domainA.domain.toUpperCase().localeCompare(domainB.domain.toUpperCase()) * sortFactor
          );
        }

        if (filters.ordering.includes('user_count')) {
          return (domainA.user_count - domainB.user_count) * sortFactor;
        }

        if (filters.ordering.includes('admin_count')) {
          return (domainA.admin_count - domainB.admin_count) * sortFactor;
        }

        return 0;
      });
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishCsvDownload());
  }
};

export const fetchDomains = async (dispatch, getState) => {
  const { domains, organisations, profile } = getState();

  let organisationId =
    organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
      ? 'all'
      : organisations.selectedOrganisationId;

  if (organisationId === null) {
    organisationId = profile.data.organisation_id;
  }

  dispatch(initLoading());

  try {
    const {
      data: { count, results, min_admin_count, max_admin_count, min_user_count, max_user_count },
    } = await AdminApi.getPaginatedDomains({
      organisationId,
      filters: domains.filters,
    });

    dispatch(updateDomains({ addedDomains: results }));
    dispatch(updateCount(count));
    dispatch(updateMinMax({ min_admin_count, max_admin_count, min_user_count, max_user_count }));
    return DOMAINS_RESPONSES.OK;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoading());
  }
};

export const createDomain = ({ organisationId, domainName }) => async dispatch => {
  try {
    const { data: domain } = await AdminApi.createDomain({ organisationId, domainName });
    dispatch(updateDomains({ addedDomains: [domain] }));
    dispatch(fetchDomains);
    return domain;
  } catch (errorDomain) {
    if (errorDomain?.response.status === 409) {
      throw DOMAINS_RESPONSES.ERROR_DOMAIN_EXISTS;
    }
    throw DOMAINS_RESPONSES.ERROR;
  }
};

export const deleteDomain = identifier => async dispatch => {
  try {
    await AdminApi.deleteDomain(identifier);
    dispatch(fetchDomains);
    return DOMAINS_RESPONSES.OK;
  } catch (e) {
    throw DOMAINS_RESPONSES.ERROR;
  }
};

export default domainsSlice.reducer;
