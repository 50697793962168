import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from 'layouts/Auth';
import LoggedContent from 'layouts/LoggedContent';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: '/auth',
    component: Auth,
    routes: [
      {
        path: '/auth/login',
        component: lazy(() => import('views/Login')),
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register')),
      },
      {
        path: '/auth/recover-2fa',
        exact: true,
        component: lazy(() => import('views/Recover2FA')),
      },
      {
        path: '/auth/recover-password',
        exact: true,
        component: lazy(() => import('views/RecoverPassword')),
      },
      {
        path: '/auth/confirm-pin',
        component: lazy(() => import('views/ConfrmPin')),
      },
      {
        path: '/auth/confirm-email',
        component: lazy(() => import('views/ConfirmEmail')),
      },
    ],
  },
  {
    path: '*',
    component: LoggedContent,
    routes: [
      {
        path: '/overview',
        exact: true,
        component: lazy(() => import('views/Overview')),
      },
      {
        path: '/confirm-admin',
        exact: true,
        component: lazy(() => import('views/ConfirmAdmin')),
      },
      {
        path: '/statistics',
        exact: true,
        component: lazy(() => import('views/Statistics')),
      },
      {
        path: '/admins',
        component: lazy(() => import('containers/AdminsController')),
        routes: [
          {
            path: '/admins',
            exact: true,
            component: lazy(() => import('views/Admins')),
          },
          {
            path: '/admins/organisation/:organisationId',
            exact: true,
            component: lazy(() => import('views/Admins')),
          },
          {
            path: '/admins/create',
            exact: true,
            component: lazy(() => import('views/AdminCreate')),
          },
          { path: '/admins/:emailHash', component: lazy(() => import('views/AdminDetail')) },
        ],
      },
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('views/Profile')),
      },
      {
        path: '/domains',
        exact: true,
        component: lazy(() => import('views/Domains')),
      },
      {
        path: '/domains/organisation/:organisationId',
        exact: true,
        component: lazy(() => import('views/Domains')),
      },
      {
        path: '/settings',
        component: lazy(() => import('containers/SettingsController')),
        routes: [
          {
            path: '/settings/login-registration',
            exact: true,
            component: lazy(() => import('views/SettingsLoginRegistration')),
          },
          {
            path: '/settings/privacy/',
            exact: true,
            component: lazy(() => import('views/SettingsPrivacy')),
          },
          {
            path: '/settings/retention',
            exact: true,
            component: lazy(() => import('views/SettingsRetention')),
          },
          {
            path: '/settings/email',
            exact: true,
            component: lazy(() => import('views/SettingsEmail')),
          },
          {
            path: '/settings/style',
            exact: true,
            component: lazy(() => import('views/SettingsStyle')),
          },
          {
            path: '/settings/permissions',
            exact: true,
            component: lazy(() => import('views/SettingsPermissions')),
          },
          {
            path: '/settings/ldap',
            exact: true,
            component: lazy(() => import('views/SettingsLDAP')),
          },
          {
            path: '/settings/archives',
            exact: true,
            component: lazy(() => import('views/SettingsArchives')),
          },
        ],
      },
      {
        path: '/integrations',
        component: lazy(() => import('containers/IntegrationsController')),
        routes: [
          {
            path: '/integrations',
            exact: true,
            component: lazy(() => import('views/Integrations')),
          },
          {
            path: '/integrations/:id',
            component: lazy(() => import('views/IntegrationDetail')),
          },
        ],
      },
      {
        path: '/users',
        component: lazy(() => import('containers/UsersController')),
        routes: [
          {
            path: '/users',
            exact: true,
            component: lazy(() => import('views/Users')),
          },
          {
            path: '/users/organisation/:organisationId',
            exact: true,
            component: lazy(() => import('views/Users')),
          },
          {
            path: '/users/invite',
            exact: true,
            component: lazy(() => import('views/UserInvite')),
          },
          {
            path: '/users/import',
            exact: true,
            component: lazy(() => import('views/UserImport')),
          },
          {
            path: '/users/:id',
            exact: true,
            component: lazy(() => import('views/UserDetail')),
          },
        ],
      },
      {
        path: '/circles',
        component: lazy(() => import('containers/UsersController')),
        routes: [
          {
            component: lazy(() => import('containers/GroupsController')),
            routes: [
              {
                path: '/circles',
                exact: true,
                component: lazy(() => import('views/Groups')),
              },
              {
                path: '/circles/organisation/:organisationId',
                exact: true,
                component: lazy(() => import('views/Groups')),
              },
              {
                path: '/circles/:id',
                exact: true,
                component: lazy(() => import('views/GroupDetail')),
              },
            ],
          },
        ],
      },
      {
        path: '/organisations',
        component: lazy(() => import('containers/OrganisationsController')),
        routes: [
          {
            path: '/organisations',
            exact: true,
            component: lazy(() => import('views/Organisations')),
          },
          {
            path: '/organisations/:id',
            component: lazy(() => import('views/OrganisationDetail')),
          },
        ],
      },
      {
        path: '/audit-log',
        exact: true,
        component: lazy(() => import('views/AuditLog')),
      },
      {
        path: '/federation',
        component: lazy(() => import('containers/FederationContainer')),
        routes: [
          { path: '/federation', exact: true, component: lazy(() => import('views/Federation')) },
        ],
      },
    ],
  },
];

export default routes;
