import { createSelector, createSlice } from '@reduxjs/toolkit';

import AdminApi from 'api/AdminApi';
import { changeI18N } from 'i18n';
import { resetApp } from 'features/auth/authSlice';
import { showHttpError } from 'features/httpError/httpErrorSlice';
import { batch } from 'react-redux';

export const PROFILE_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  NOT_FOUND: 'not_found',
  FORBIDDEN: 'forbidden',
  CONFLICT: 'conflict',
};

const initialState = {
  isFetched: false,
  data: null,
  lastFetch: undefined,
  permissions: {
    allow_view_users: false,
    allow_modify_users: false,
    allow_view_groups: false,
    allow_modify_groups: false,
    allow_view_api_keys: false,
    allow_modify_api_keys: false,
    allow_view_admins: false,
    allow_modify_admins: false,
    allow_view_domains: false,
    allow_modify_domains: false,
    allow_view_settings: false,
    allow_modify_settings: false,
    allow_manage_ldap_sync: false,
    allow_view_audit_log: false,
  },
};

/*
PROFILE OBJECT
  "two_factor_enabled": false,
  "email_hash": "88d82037ac89d38d279f21859b10fcd725ead16396d64f7f8dc9046bfd3dc865",
  "last_name": "TW-Demo",
  "division": "Testing",
  "company": "Teamwire-demo",
  "city": "a city",
  "phone": "+34665399916",
  "postcode": "03800",
  "address": "Sesame Str",
  "read_only": false,
  "organisation_id": 134,
  "first_name": "Clara",
  "preferred_language": "En",
  "mobile": "+34665399916",
  "country": "Spain",
  "created_at": "2017-04-29T10:13:23Z",
  "enabled": true,
  "last_login": 1583247578,
  "role": "Beta-tester",
  "super_admin": false,
  "email": "clara@teamwire-demo.de"

*/
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    finishFetch: state => {
      state.isFetched = true;
    },
    updateProfile: (state, action) => {
      const { profile } = action.payload;
      state.data = profile;
    },
    updatePermissions: (state, action) => {
      const { permissions } = action.payload;
      if (permissions) {
        state.permissions = {
          ...state.permissions,
          ...permissions,
        };
      }
    },
    updateTwoFactor: (state, action) => {
      const { enabled } = action.payload;
      state.data.two_factor_enabled = enabled;
    },
  },
});

export const {
  finishFetch,
  updateProfile,
  updateTwoFactor,
  updatePermissions,
} = profileSlice.actions;

const getProfileState = state => state.profile;

export const getIsProfileFetched = createSelector(
  [getProfileState],
  profileState => profileState.isFetched,
);
export const getProfile = createSelector([getProfileState], profileState => profileState.data);
export const getIsSuperAdmin = createSelector([getProfile], profile => profile?.super_admin);
export const getMyOrganisationId = createSelector(
  [getProfile],
  profile => profile?.organisation_id,
);
export const getPermissions = createSelector(
  [getProfileState],
  profileState => profileState.permissions,
);
export const getIsPermissionsLoading = createSelector(
  [getProfileState],
  profileState => profileState.permissions === null,
);

// THUNKS

export const fetchProfile = async dispatch => {
  let profile = {};
  let permissions = {};
  try {
    const { data } = await AdminApi.getProfile();
    profile = data;

    changeI18N(profile.preferred_language);
  } catch (error) {
    const { status } = error.response;
    if (status === 401) {
      dispatch(resetApp());
    }
    if (status === 403) {
      return PROFILE_RESPONSES.FORBIDDEN;
    }
    if (status === 404) {
      return PROFILE_RESPONSES.NOT_FOUND;
    }
    if (status === 409) {
      return PROFILE_RESPONSES.CONFLICT;
    }
  }

  try {
    const { data } = await AdminApi.getAdminPermissions(profile?.email_hash);
    permissions = data;
  } catch {
    dispatch(showHttpError());
  }

  batch(() => {
    dispatch(updateProfile({ profile }));
    dispatch(updatePermissions({ permissions }));
    dispatch(finishFetch());
  });
  return profile;
};

export const updateProfileData = props => async (dispatch, getState) => {
  const state = getState();
  const profile = getProfile(state);
  try {
    const data = {
      ...profile,
      ...props,
    };
    await AdminApi.updateProfile({ profile: data });
    dispatch(updateProfile({ profile: data }));
    return PROFILE_RESPONSES.OK;
  } catch (error) {
    const { status } = error.response;
    if (status === 401) {
      dispatch(resetApp());
    }
    if (status === 403) {
      return PROFILE_RESPONSES.FORBIDDEN;
    }
    if (status === 404) {
      return PROFILE_RESPONSES.NOT_FOUND;
    }
    if (status === 409) {
      return PROFILE_RESPONSES.CONFLICT;
    }
  }
};

export const changeLanguage = language => updateProfileData({ preferred_language: language });

export const disableTwoFactor = async dispatch => {
  try {
    await AdminApi.disableTwoFactorAuth();
    dispatch(updateTwoFactor({ enabled: false }));
  } catch (error) {
    throw error?.response?.status ?? -1;
  }
};

export const enableTwoFactor = ({ code }) => async dispatch => {
  try {
    await AdminApi.enableTwoFactorAuth(code);
    dispatch(updateTwoFactor({ enabled: true }));
  } catch (error) {
    throw error?.response?.status ?? -1;
  }
};

export default profileSlice.reducer;
