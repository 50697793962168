import { createSlice, createSelector } from '@reduxjs/toolkit';

import AdminApi from 'api/AdminApi';
import { showHttpError } from 'features/httpError/httpErrorSlice';

const initialState = {
  maxGroupCallMembers: null,
  enableVoipCalls: null,
  enableOnboardingBot: null,
  enableAdminRoleToOnboardingBot: null,
  onboardingBotAppId: null,
};

const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setGeneralSettings(state, action) {
      state = action.payload.generalSettings;
      return state;
    },
    setVoIPGeneralSettings(state, action) {
      state.maxGroupCallMembers = action.payload.maxGroupCallMembers;
      state.enableVoipCalls = action.payload.enableVoipCalls;
    },
    setOnboardingBotGeneralSettings(state, action) {
      state.enableOnboardingBot = action.payload.enableOnboardingBot;
      state.onboardingBotAppId = action.payload.onboardingBotAppId;
      state.enableAdminRoleToOnboardingBot = action.payload.enableAdminRoleToOnboardingBot;
    },
  },
});

export const getGeneralSettings = createSelector(
  state => state.generalSettings,
  state => state,
);

export const {
  setGeneralSettings,
  setMaxGroupCallMembers,
  setOnboardingBotGeneralSettings,
  setVoIPGeneralSettings,
} = generalSettingsSlice.actions;

export function fetchGeneralSettings() {
  return function (dispatch) {
    return AdminApi.getGeneralSettings()
      .then(({ data }) => {
        const generalSettings = {
          maxGroupCallMembers: data.max_group_call_members,
          enableVoipCalls: data.enable_voip_calls,
          enableOnboardingBot: data.enable_onboarding_bot,
          enableAdminRoleToOnboardingBot: data.enable_admin_role_to_onboarding_bot,
          onboardingBotAppId: data.onboarding_bot_app_id,
          maxNumberVoipCallerLimit: data.max_number_voip_caller_limit,
        };

        dispatch(
          setGeneralSettings({
            generalSettings,
          }),
        );
      })
      .catch(() => {
        dispatch(showHttpError());
      });
  };
}

export function updateVoIPGeneralSettings(value) {
  return function (dispatch) {
    return AdminApi.updateVoIPGeneralSettings(value)
      .then(() => {
        dispatch(setVoIPGeneralSettings(value));
      })
      .catch(() => {
        dispatch(showHttpError());
      });
  };
}

export function updateOnboardingBotGeneralSettings(value) {
  return function (dispatch) {
    return AdminApi.updateOnboardingBotGeneralSettings(value)
      .then(() => {
        dispatch(setOnboardingBotGeneralSettings(value));
      })
      .catch(() => {
        dispatch(showHttpError());
      });
  };
}

export default generalSettingsSlice.reducer;
