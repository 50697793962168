import { useState, useMemo, useCallback, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';
import padStart from 'lodash/padStart';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { doLogout, getLastRequest } from 'features/auth/authSlice';
import { fetchReleaseHash } from 'features/users/usersSlice';

import useInterval from 'hooks/useInterval';

import colors from 'theme/colors';

import config from 'config';

const { MAX_TIME_INACTIVE, SECONDS_TIMEOUT } = config;

const useStyles = makeStyles(theme => ({
  dialog: {
    zIndex: '2000 !important',
  },
  modal: {
    padding: theme.spacing(7.75),
    width: '600px',
  },
  closeAction: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  header: {
    padding: theme.spacing(0, 0, 2.5, 0),
  },
  title: {
    fontSize: theme.typography.pxToRem(29),
  },
  content: {
    padding: 0,
  },
  text: {
    margin: theme.spacing(2.5, 0, 5, 0),
  },
  timeout: {
    textAlign: 'center',
    backgroundColor: colors.veryLightGrey,
    padding: theme.spacing(2.5),
    fontFamily: '"Roboto"',
    fontSize: theme.typography.pxToRem(70),
    fontWeight: 100,
    color: colors.darkBlue,
    lineHeight: 1,
  },
  buttons: {
    padding: theme.spacing(5.5, 0, 0, 0),
    '& > *': {
      flex: 1,
    },
  },
}));

const TimeoutCountdown = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [diff, setDiff] = useState(0);
  const showModal = useMemo(() => diff >= MAX_TIME_INACTIVE - SECONDS_TIMEOUT, [diff]);
  const countdown = useMemo(() => MAX_TIME_INACTIVE - diff, [diff]);

  const lastRequest = useSelector(getLastRequest);
  const lastRequestDate = useMemo(() => moment(lastRequest), [lastRequest]);

  const formatSeconds = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;
    const min = t('min');
    const sec = t('secs');
    return `${minutes} ${min} ${padStart(seconds, 2, '0')} ${sec}`;
  };

  const handleOnCloseSession = useCallback(() => {
    dispatch(doLogout());
  }, [dispatch]);

  const handleOnContinue = useCallback(() => {
    dispatch(fetchReleaseHash);
  }, [dispatch]);

  useInterval(() => {
    const now = moment();
    setDiff(now.diff(lastRequestDate, 'seconds'));
  }, 1000);

  const loggedOut = useRef(false);
  useEffect(() => {
    if (diff >= MAX_TIME_INACTIVE) {
      if (!loggedOut.current) {
        handleOnCloseSession();
        loggedOut.current = true;
      }
    }
  }, [diff, handleOnCloseSession]);

  return (
    <Dialog
      open={showModal}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ root: classes.dialog, paper: classes.modal }}
    >
      <div className={classes.closeAction}>
        <IconButton onClick={handleOnContinue}>
          <Close />
        </IconButton>
      </div>
      <DialogTitle disableTypography className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          {t('SESSION_WILL_EXPIRE')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.text}>
          {t('SESSION_WILL_EXPIRE_IN')}
        </DialogContentText>
        <div className={classes.timeout}>{formatSeconds(countdown)}</div>
      </DialogContent>

      <DialogActions className={clsx(classes.buttons)}>
        <Button variant="contained" onClick={handleOnCloseSession} disableRipple>
          {t('logout')}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleOnContinue}>
          {t('STAY_CONNECTED')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(TimeoutCountdown);
