import { createRef } from 'react';
import { SnackbarProvider } from 'notistack';

import { Close } from '@material-ui/icons';
import { IconButton, makeStyles } from '@material-ui/core';
import colors from 'theme/colors';

const notistackRef = createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: colors.softGreen,
    color: colors.darkBlue,
    '& a': {
      color: theme.palette.white,
    },
  },
  error: { backgroundColor: '#fe576e' },
  warning: { backgroundColor: colors.bismark },
  info: { backgroundColor: colors.mainBlue },
  containerAnchorOriginBottomCenter: {
    [theme.breakpoints.up('xs')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));

export const NotificationsProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      ref={notistackRef}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        containerAnchorOriginBottomCenter: classes.containerAnchorOriginBottomCenter,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      variant="success"
      action={key => (
        <IconButton onClick={onClickDismiss(key)} style={{ color: '#fff' }}>
          <Close />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
