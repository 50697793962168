import { useCallback, memo } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Button, IconButton } from '@material-ui/core';
import { Menu as MenuIcon, ExitToApp, PersonOutlined } from 'components/Icons';

import { doLogout } from 'features/auth/authSlice';

import { rgba } from 'polished';

const useStyles = makeStyles(theme => ({
  header: {
    boxShadow: `0 1px 3px 0 ${rgba(theme.palette.black, 0.04)}`,
    zIndex: theme.zIndex.modal + 10,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  headerMenu: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  leftTopBar: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TopBar = ({ onToggleNavBar, drawerOpened }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const logout = useCallback(async () => {
    dispatch(doLogout());
  }, [dispatch]);

  return (
    <AppBar color="inherit" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftTopBar}>
          <IconButton className={classes.menuButton} color="inherit" onClick={onToggleNavBar}>
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <img
              className={classes.logo}
              alt="TeamwireLogo"
              src={`${process.env.PUBLIC_URL}/images/logos/tw-logo.svg`}
            />
          </RouterLink>
        </div>
        <div className={classes.headerMenu}>
          <Button
            className={classes.buttonHeader}
            color="inherit"
            component={RouterLink}
            to="/profile"
            startIcon={<PersonOutlined />}
            size="large"
          >
            {t('PROFILE')}
          </Button>
          <Button
            className={classes.buttonHeader}
            color="inherit"
            onClick={logout}
            data-testid="logout"
            startIcon={<ExitToApp />}
            size="large"
          >
            {t('logout')}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default memo(TopBar);
