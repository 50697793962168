import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { sortBy } from 'lodash';

import AdminApi from 'api/AdminApi';

import { getIsSuperAdmin, getMyOrganisationId } from 'features/profile/profileSlice';
import { resetApp } from 'features/auth/authSlice';
import { getReleaseHash } from 'features/users/usersSlice';

import sortComparerBy from 'utils/sortComparerBy';
import cleanString from 'utils/cleanString';

export const ORGANISATIONS_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ORGANISATION_ALREADY_EXISTS: 'already_exists',
  DOMAIN_ALREADY_EXISTS: 'domain_aready_exists',
  FORBIDDEN: 'forbidden',
  NOT_FOUND: 'not_found',
  BAD_REQUEST: 'bad_request',
  CONFLICT: 'conflict',
};

/*
ORGANISATION OBJECT
  state
  id
  name
ORGANISATION OBJECT DETAILED
{
  "id": 310,
  "name": "2.pl",
  "state": "Enabled",
  "license_count": null,
  "admin_count": 0,
  "user_count": 1,
  "group_count": 0,
  "domain_count": 1,
  "privacy_mode": "Internal and external",
  "domain_ids": [
    379
  ]
*/

const organisationsAdapter = createEntityAdapter({
  selectId: organisation => organisation.id,
  sortComparer: sortComparerBy('id'),
});

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState: organisationsAdapter.getInitialState({
    isLoading: true,
    isFetched: false,
    lastUpdated: null,
    selectedOrganisationId: null,
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
      state.isFetched = true;
    },
    updateOrganisations: (state, action) => {
      const { addedOrganisations = [], deletedOrganisationsKeys = [] } = action.payload;

      state.lastUpdated = new Date().toUTCString();

      organisationsAdapter.upsertMany(state, addedOrganisations);
      organisationsAdapter.removeMany(state, deletedOrganisationsKeys);
    },
    updateOrganisation: (state, action) => {
      const { id, props = {} } = action.payload;
      organisationsAdapter.upsertOne(state, { id, ...props });
    },
    setSelectedOrganisation: (state, action) => {
      const { organisationId } = action.payload;
      state.selectedOrganisationId = organisationId;
    },
  },
});

export const {
  initLoading,
  finishLoading,
  updateOrganisations,
  updateOrganisation,
  setSelectedOrganisation,
} = organisationsSlice.actions;

// SELECTORS
const getOrganisationsState = state => state.organisations;
export const organisationsGlobalSelector = organisationsAdapter.getSelectors(getOrganisationsState);

export const getOrganisationsLastUpdated = createSelector(
  [getOrganisationsState],
  state => state.lastUpdated,
);
export const getIsOrganisationsLoading = createSelector(
  [getOrganisationsState],
  state => state.isLoading,
);
export const getIsOrganisationsFetched = createSelector(
  [getOrganisationsState],
  state => state.isFetched,
);
export const getOrganisationsList = createSelector(
  [organisationsGlobalSelector.selectAll],
  organisations => {
    return sortBy(organisations, organisation => cleanString(organisation.name)).map(o => ({
      ...o,
      disabled: Boolean(o.federation),
    }));
  },
);
export const getOrganisationById = organisationsGlobalSelector.selectById;

export const getSelectedOrganisationId = createSelector(
  [getIsSuperAdmin, state => state.organisations.selectedOrganisationId, getMyOrganisationId],
  (iAmSuperAdmin, selectedOrganisationId, profileOrganisationId) => {
    if (iAmSuperAdmin) {
      return selectedOrganisationId || profileOrganisationId;
    }
    return '';
  },
);
export const getSelectedOrganisation = createSelector(
  [getReleaseHash, getSelectedOrganisationId, organisationsGlobalSelector.selectEntities],
  (releaseHash, selectedOrganisationId, entities) => {
    if (releaseHash && selectedOrganisationId === releaseHash.sys_org_id) {
      return 'SYS_ORG_ID';
    }
    if (selectedOrganisationId === 'ALL_ORGANISATIONS') {
      return undefined;
    }
    return entities[selectedOrganisationId] || { id: selectedOrganisationId };
  },
);

// THUNKS
const fetchOrganisations = async (dispatch, getState) => {
  const state = getState();
  const isSuperAdmin = getIsSuperAdmin(state);
  if (!isSuperAdmin ?? true) {
    dispatch(finishLoading());
    return;
  }
  dispatch(initLoading());
  try {
    const { data: organisations } = await AdminApi.getAllOrganisations();
    batch(() => {
      dispatch(updateOrganisations({ addedOrganisations: organisations }));
      dispatch(finishLoading());
    });
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    dispatch(finishLoading());
    const { status } = error.response;
    if (status === 401) {
      dispatch(resetApp());
    }
    if (status === 403) {
      return ORGANISATIONS_RESPONSES.FORBIDDEN;
    }
    if (status === 404) {
      return ORGANISATIONS_RESPONSES.NOT_FOUND;
    }
    return ORGANISATIONS_RESPONSES.ERROR;
  }
};

export const fetchAllOrganisations = fetchOrganisations;

export const createOrganisation = ({ name = '', domains = [] }) => async dispatch => {
  try {
    const { data: organisation } = await AdminApi.createOrganisation({ name, domains });

    dispatch(updateOrganisations({ addedOrganisations: [organisation] }));
    return organisation.id;
  } catch (createOrganisationError) {
    if (createOrganisationError.response) {
      const { status, data } = createOrganisationError.response;

      if (status === 403) {
        // eslint-disable-next-line no-throw-literal
        throw { code: ORGANISATIONS_RESPONSES.FORBIDDEN };
      }
      if (status === 404) {
        // eslint-disable-next-line no-throw-literal
        throw { code: ORGANISATIONS_RESPONSES.NOT_FOUND };
      }
      if (status === 406) {
        const errorMessages = [];
        (data?.error ?? []).forEach(domainError => {
          errorMessages.push(`${domainError.error}: ${domainError.name}`);
        });
        // eslint-disable-next-line no-throw-literal
        throw { code: ORGANISATIONS_RESPONSES.DOMAIN_ALREADY_EXISTS, content: errorMessages };
      }
      if (status === 409) {
        // eslint-disable-next-line no-throw-literal
        throw { code: ORGANISATIONS_RESPONSES.ORGANISATION_ALREADY_EXISTS };
      }
    }
    throw ORGANISATIONS_RESPONSES.ERROR;
  }
};

export const mergeOrganisations = ({ source, target }) => async dispatch => {
  try {
    await AdminApi.mergeOrganisations({ sourceId: source, targetId: target });

    dispatch(updateOrganisations({ deletedOrganisationsKeys: [source] }));
    dispatch(fetchOrganisations);
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        throw ORGANISATIONS_RESPONSES.BAD_REQUEST;
      }
      if (status === 401) {
        dispatch(resetApp());
      }
      if (status === 403) {
        throw ORGANISATIONS_RESPONSES.FORBIDDEN;
      }
      if (status === 404) {
        throw ORGANISATIONS_RESPONSES.NOT_FOUND;
      }
      if (status === 409) {
        throw ORGANISATIONS_RESPONSES.CONFLICT;
      }
    }
    throw ORGANISATIONS_RESPONSES.ERROR;
  }
};

export const deleteOrganisation = id => async dispatch => {
  try {
    await AdminApi.deleteOrganisation(id);
    dispatch(updateOrganisations({ deletedOrganisationsKeys: [id] }));
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        return ORGANISATIONS_RESPONSES.BAD_REQUEST;
      }
      if (status === 401) {
        dispatch(resetApp());
      }
      if (status === 403) {
        return ORGANISATIONS_RESPONSES.FORBIDDEN;
      }
      if (status === 404) {
        return ORGANISATIONS_RESPONSES.NOT_FOUND;
      }
      if (status === 409) {
        return ORGANISATIONS_RESPONSES.CONFLICT;
      }
    }
    return ORGANISATIONS_RESPONSES.ERROR;
  }
};

export const updateOrganisationStatus = (id, action = 'enable') => async dispatch => {
  try {
    await AdminApi.updateOrganisation({
      id,
      props: {
        state: action === 'enable' ? 'enabled' : 'disabled',
      },
    });
    dispatch(
      updateOrganisation({
        id,
        props: {
          state: action === 'enable' ? 'Enabled' : 'Disabled',
        },
      }),
    );
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        return ORGANISATIONS_RESPONSES.BAD_REQUEST;
      }
      if (status === 401) {
        dispatch(resetApp());
      }
      if (status === 403) {
        return ORGANISATIONS_RESPONSES.FORBIDDEN;
      }
      if (status === 404) {
        return ORGANISATIONS_RESPONSES.NOT_FOUND;
      }
      if (status === 409) {
        return ORGANISATIONS_RESPONSES.CONFLICT;
      }
    }
    return ORGANISATIONS_RESPONSES.ERROR;
  }
};

export const updateOrganisationName = (id, name = '') => async dispatch => {
  const fetchProps = {
    organisation_name: name,
  };
  const props = {
    name,
  };
  try {
    await AdminApi.updateOrganisation({
      id,
      props: fetchProps,
    });

    dispatch(
      updateOrganisation({
        id,
        props,
      }),
    );
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        return ORGANISATIONS_RESPONSES.BAD_REQUEST;
      }
      if (status === 401) {
        dispatch(resetApp());
      }
      if (status === 403) {
        return ORGANISATIONS_RESPONSES.FORBIDDEN;
      }
      if (status === 404) {
        return ORGANISATIONS_RESPONSES.NOT_FOUND;
      }
      if (status === 409) {
        return ORGANISATIONS_RESPONSES.CONFLICT;
      }
    }
    return ORGANISATIONS_RESPONSES.ERROR;
  }
};
export const updateOrganisationLicenses = (id, licenses = null) => async dispatch => {
  const fetchProps = {
    organisation_license_count: licenses,
  };
  const props = {
    license_count: licenses,
  };
  try {
    await AdminApi.updateOrganisation({
      id,
      props: fetchProps,
    });

    dispatch(
      updateOrganisation({
        id,
        props,
      }),
    );
    return ORGANISATIONS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        throw ORGANISATIONS_RESPONSES.BAD_REQUEST;
      }
      if (status === 401) {
        dispatch(resetApp());
      }
      if (status === 403) {
        throw ORGANISATIONS_RESPONSES.FORBIDDEN;
      }
      if (status === 404) {
        throw ORGANISATIONS_RESPONSES.NOT_FOUND;
      }
      if (status === 409) {
        throw ORGANISATIONS_RESPONSES.CONFLICT;
      }
    }
    throw ORGANISATIONS_RESPONSES.ERROR;
  }
};

export default organisationsSlice.reducer;
