import { memo } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
  },
  toolbar: {
    justifyContent: 'center',
    '& img': {
      height: '36px',
    },
  },
}));

const AuthHeader = () => {
  const classes = useStyles();
  return (
    <AppBar color="inherit" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img alt="Teamwire" src={process.env.PUBLIC_URL + '/images/logos/teamwire-logo.png'} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default memo(AuthHeader);
