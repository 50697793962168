import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';

export const RESET_RESPONSES = {
  OK: 'ok',
  GENERIC_ERROR: 'generic_error',
  BAD_REQUEST: 'bad_request',
  UNAUTHORIZED: 'unauthorized',
  CONFLICT: 'conflict',
};

export const resetInitialState = {
  isLoading: false,
  isReseted: false,
};

const resetSlice = createSlice({
  name: 'reset',
  initialState: resetInitialState,
  reducers: {
    resetRequest: state => {
      state.isLoading = true;
    },
    resetFinished: state => {
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.isReseted = true;
      state.isLoading = false;
    },
    resetFailed: (state, action) => {
      state.isLoading = false;
      state.isReseted = false;
      state.error = action.payload || RESET_RESPONSES.GENERIC_ERROR;
    },
  },
});

export const { resetRequest, resetSuccess, resetFailed, resetFinished } = resetSlice.actions;

export const doReset = (values = {}) => async (dispatch, getState) => {
  try {
    dispatch(resetRequest());
    await AdminApi.resetPassword({
      email: values.email,
      code: values.code,
      new_password: values.password,
    });

    dispatch(resetSuccess());

    return RESET_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(resetFailed(RESET_RESPONSES.BAD_REQUEST));
        return RESET_RESPONSES.BAD_REQUEST;
      }

      if (status === 401) {
        dispatch(resetFailed(RESET_RESPONSES.UNAUTHORIZED));
        return RESET_RESPONSES.UNAUTHORIZED;
      }

      if (status === 409) {
        dispatch(resetFailed(RESET_RESPONSES.CONFLICT));
        return RESET_RESPONSES.CONFLICT;
      }
    }
    dispatch(resetFailed(RESET_RESPONSES.GENERIC_ERROR));
    return RESET_RESPONSES.GENERIC_ERROR;
  }
};

const getResetState = state => state.reset;

export const getResetLoading = createSelector([getResetState], resetState => resetState.isLoading);
export const getReseted = createSelector([getResetState], resetState => resetState.isReseted);
export const getResetError = createSelector([getResetState], resetState => resetState.error);

export default resetSlice.reducer;
