import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Box } from '@material-ui/core';

import { isAuthLoading } from 'features/auth/authSlice';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.appBar + 100,
  },
}));

const AuthLoading = () => {
  const classes = useStyles();
  const isFetching = useSelector(isAuthLoading);

  if (isFetching) {
    return (
      <Box position="absolute" top={0} left={0} right={0}>
        <LinearProgress data-testid="loading" className={classes.root} color="secondary" />
      </Box>
    );
  }
  return null;
};

export default AuthLoading;
