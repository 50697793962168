import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getShowError, getErrorMessage, resetHttpError } from 'features/httpError/httpErrorSlice';

function HttpErrorMonitor() {
  const showError = useSelector(getShowError);
  const errorMessage = useSelector(getErrorMessage);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showError) {
      enqueueSnackbar(errorMessage || t('something_went_wrong'), { variant: 'error' });
      dispatch(resetHttpError());
    }
  }, [dispatch, enqueueSnackbar, showError, errorMessage, t]);

  return null;
}

export { HttpErrorMonitor };
