import { useMemo } from 'react';
import { rgba } from 'polished';

import { makeStyles, ThemeProvider, withStyles } from '@material-ui/styles';
import { createMuiTheme, Backdrop, Box } from '@material-ui/core';

import { baseTheme } from 'theme';
import colors from 'theme/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
  const width = theme?.props?.Loading?.size ?? 10;
  const height = theme?.props?.Loading?.size ?? 10;

  const dotWidth = width + 'px';
  const dotHeight = height + 'px';
  const dotRadius = width / 2 + 'px';
  const dotSpacing = width + width / 2;

  const left = -9999;
  const leftPos = left + 'px';
  const x1 = -left - dotSpacing + 'px';
  const x2 = -left + 'px';
  const x3 = -left + dotSpacing + 'px';

  const y1 = -dotSpacing + 'px';
  const y2 = 0;
  const y3 = dotSpacing + 'px';

  const dotColor = theme.palette.secondary.main;
  const color0 = rgba(dotColor, 0);
  const color1 = rgba(dotColor, 1);

  const colorBefore0 = rgba(dotColor, 0);
  const colorBefore1 = rgba(dotColor, 1);
  const colorAfter0 = rgba(dotColor, 0);
  const colorAfter1 = rgba(dotColor, 1);

  const dot = ({
    width = dotWidth,
    height = dotHeight,
    radius = dotRadius,
    color = dotColor,
  } = {}) => ({
    width: width,
    height: height,
    borderRadius: radius,
    backgroundColor: dotColor,
    color: color,
  });

  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: theme.spacing(dotSpacing / 8, 0),
      minWidth: theme.props.Loading.dotSize === 'small' ? '36px' : '45px',
      '& *, & *::after, & *::before': {
        boxSizing: 'content-box',
      },
    },
    loadingDots: {
      ...dot(),
      position: 'relative',
      left: leftPos,
      boxShadow: `${x2} 0 0 0 ${dotColor}`,
      animation: '$dotFalling 1s infinite linear',
      animationDelay: '.1s',
      '&::before, &::after': {
        ...dot(),
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: 0,
      },
      '&::before': {
        animation: '$dotFallingBefore 1s infinite linear',
        animationDelay: '0s',
      },

      '&::after': {
        animation: '$dotFallingAfter 1s infinite linear',
        animationDelay: '.2s',
      },
    },
    '@keyframes dotFalling': {
      '0%': {
        boxShadow: `${x2} ${y1} 0 ${color0}`,
      },
      '25%, 50%, 75%': {
        boxShadow: `${x2} ${y2} 0 ${color1}`,
      },
      '100%': {
        boxShadow: `${x2} ${y3} 0 0 ${color0}`,
      },
    },
    '@keyframes dotFallingBefore': {
      '0%': {
        boxShadow: `${x1} ${y1} 0 0 ${colorBefore0}`,
      },
      '25%, 50%, 75%': {
        boxShadow: `${x1} ${y2} 0 0 ${colorBefore1}`,
      },
      '100%': {
        boxShadow: `${x1} ${y3} 0 0 ${colorBefore0}`,
      },
    },
    '@keyframes dotFallingAfter': {
      '0%': {
        boxShadow: `${x3} ${y1} 0 0 ${colorAfter0}`,
      },
      '25%, 50%, 75%': {
        boxShadow: `${x3} ${y2} 0 0 ${colorAfter1}`,
      },
      '100%': {
        boxShadow: `${x3} ${y3} 0 0 ${colorAfter0}`,
      },
    },
  };
});

const LoadingDots = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.loadingDots} />
    </div>
  );
};

const mapColors = {
  default: colors.softGreen,
  gray: colors.gray98,
};
const Loading = ({ size, color = 'default' }) => {
  const theme = useMemo(() => {
    const dotSize = size === 'small' ? 8 : 10;
    const secondaryColor = mapColors[color] || mapColors.default;

    return createMuiTheme({
      ...baseTheme,
      palette: { secondary: { main: secondaryColor } },
      props: { ...baseTheme.props, Loading: { size: dotSize } },
    });
  }, [size, color]);

  return (
    <ThemeProvider theme={theme}>
      <LoadingDots />
    </ThemeProvider>
  );
};

export const BackdropLoading = withStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    marginTop: 12,
  },
}))(({ open, withText, classes, ...props }) => {
  const [t] = useTranslation();

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Loading {...props} />
      {withText ? <span className={classes.text}>{t('wait_to_download')}</span> : null}
    </Backdrop>
  );
});

export const CenterBoxLoading = ({ boxProps, loadingProps }) => (
  <Box display="flex" flexGrow="1" justifyContent="center" alignItems="center" {...boxProps}>
    <Loading {...loadingProps} />
  </Box>
);

export default Loading;
