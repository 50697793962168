import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';

export const RECOVER_RESPONSES = {
  OK: 'ok',
  GENERIC_ERROR: 'generic_error',
  UNAUTHORIZED: 'unauthorized',
  INVALID_NUMBER: 'invalid_number',
  CONFLICT: 'conflict',
  ATTEMPTS: 'attempts',
};

export const recoverInitialState = {
  isLoading: false,
  isRecovered: false,
};

const recover2FASlice = createSlice({
  name: 'recover2FA',
  initialState: recoverInitialState,
  reducers: {
    recoverRequest: state => {
      state.isLoading = true;
    },
    recoverFinished: state => {
      state.isLoading = false;
    },
    recoverSuccess: (state, action) => {
      state.isRecovered = true;
      state.isLoading = false;
    },
    recoverFailed: (state, action) => {
      state.isLoading = false;
      state.isRecovered = false;
      state.error = action.payload || RECOVER_RESPONSES.GENERIC_ERROR;
    },
  },
});

export const {
  recoverRequest,
  recoverSuccess,
  recoverFailed,
  recoverFinished,
} = recover2FASlice.actions;

export const doRecover = (values = {}) => async (dispatch, getState) => {
  try {
    dispatch(recoverRequest());
    await AdminApi.recover2FA({
      email: values.email,
      mobile: values.phone,
    });
    dispatch(recoverSuccess());

    return RECOVER_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(recoverFailed(RECOVER_RESPONSES.INVALID_NUMBER));
        return RECOVER_RESPONSES.INVALID_NUMBER;
      }

      if (status === 401) {
        dispatch(recoverFailed(RECOVER_RESPONSES.UNAUTHORIZED));
        return RECOVER_RESPONSES.UNAUTHORIZED;
      }
      if (status === 409) {
        dispatch(recoverFailed(RECOVER_RESPONSES.CONFLICT));
        return RECOVER_RESPONSES.CONFLICT;
      }
      if (status === 429) {
        dispatch(recoverFailed(RECOVER_RESPONSES.ATTEMPTS));
        return RECOVER_RESPONSES.ATTEMPTS;
      }
    }
    dispatch(recoverFailed(RECOVER_RESPONSES.GENERIC_ERROR));
    return RECOVER_RESPONSES.GENERIC_ERROR;
  }
};

const getRecover2FAState = state => state.recover2FA;

export const getRecoverLoading = createSelector(
  [getRecover2FAState],
  recover2FAState => recover2FAState.isLoading,
);
export const getRecovered = createSelector(
  [getRecover2FAState],
  recover2FAState => recover2FAState.isRecovered,
);
export const getRecoverError = createSelector(
  [getRecover2FAState],
  recover2FAState => recover2FAState.error,
);

export default recover2FASlice.reducer;
