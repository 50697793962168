import colors from 'theme/colors';

const MuiButton = {
  contained: {
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: colors.gray98,
    color: colors.darkestBlue,
  },
  containedSecondary: {
    color: colors.darkBlue,
  },
};

export default MuiButton;
