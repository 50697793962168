import axios from 'utils/axios';
import oboe from 'oboe';
import { CancelToken } from 'axios';

import config from 'config';
import getCookie from 'utils/getCookie';
const { apiUrl, apiUrlDrf } = config;

function getFormattedQueryStringParameters(filters) {
  let queryStringParameters = '';

  if (Object.entries(filters).length > 0) {
    Object.entries(filters).forEach(([filterName, filterValue]) => {
      if (filterValue !== null) {
        if (queryStringParameters !== '') {
          queryStringParameters += '&';
        } else {
          queryStringParameters += '?';
        }
        queryStringParameters += `${filterName}=${filterValue}`;
      }
    });
  }

  return queryStringParameters;
}

let cancelExecutor;

class AdminApi {
  static login = ({ email, password, code2FA }) => {
    const payload = code2FA ? { email, password, token: code2FA } : { email, password };
    return axios.post('/login', payload);
  };

  static logout = () => {
    return axios.delete('/login');
  };

  static register = payload =>
    axios.post('/register', {
      ...payload,
      email_confirmation_link: '/dashboard/#/auth/confirm-email?secret=',
    });

  static getPaginatedUsers = ({ organisationId, filters = {} }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    if (cancelExecutor) {
      cancelExecutor();
    }

    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/users/${queryStringParameters}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelExecutor = c;
        }),
      },
    );
  };

  static getAllUsers = () => {
    return oboe({
      url: apiUrl + '/users/',
      method: 'GET',
      withCredentials: true,
    });
  };

  static getProfile = () => axios.get('/admins/self/');

  static updateProfile = ({ profile }) => axios.put('/admins/self/', profile);

  static getReleaseHash = () => axios.get('/backend_info');

  static recover2FA = ({ email, mobile }) => axios.post('/2fa/recover', { email, mobile });

  static requestChangePassword = ({ email, mobile }) => axios.post('/password', { email, mobile });

  static resetPassword = ({ email, code, new_password }) =>
    axios.put('/password', { email, code, new_password });

  static getDashboardStats = () => axios.get('/stats/dashboard');

  static getStatsActiveUsers = ({ organisationId = '', month, year }) =>
    axios.get(`/stats/active_users/${organisationId}`, { params: { year, month } });

  static getStatsActiveDevices = ({ organisationId = '', month, year }) =>
    axios.get(`/stats/activated_connectors/${organisationId}`, { params: { year, month } });

  static getStatsSentMessages = ({ organisationId = '', month, year }) =>
    axios.get(`/stats/sent_messages/${organisationId}`, { params: { year, month } });

  static getAllOrganisations = () => axios.get('/organisations?details=true');

  static createOrganisation = ({ name, domains }) =>
    axios.post('/organisations', { organisation_name: name, domains });

  static updateOrganisation = ({ id, props = {} }) => axios.put(`/organisations/${id}`, props);

  static deleteOrganisation = id => axios.delete(`/organisations/${id}`);

  static mergeOrganisations = ({ sourceId, targetId }) =>
    axios.post('/organisations/merge/', {
      source_organisation_id: sourceId,
      target_organisation_id: targetId,
    });

  static getAllGroups = () => axios.get('/groups');

  static getPaginatedGroups = ({ organisationId, filters = {} }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/circles/${queryStringParameters}`,
    );
  };

  static getGroup = ({ circleId, organisationId }) =>
    axios.get(`${apiUrlDrf}/organisations/${organisationId}/circles/${circleId}/`);

  static createGroup = ({ title = '', organisationId = null } = {}) =>
    axios.post('/groups/', { title, organisation_id: organisationId });

  static updateGroup = ({ id, props = {} }) => axios.put(`/groups/${id}`, props);

  static deleteGroup = ({ id }) => axios.delete(`/groups/${id}`);

  static getAllAdmins = () => axios.get('/admins');

  static getPaginatedAdmins = ({ organisationId, filters = {} }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/admins/${queryStringParameters}`,
    );
  };

  static getAdmin = ({ emailHash }) => axios.get(`/admins/${emailHash}/`);

  static updateAdmin = ({ emailHash, props }) => axios.put(`/admins/${emailHash}/`, props);

  static createAdmin = values => axios.post('/admins/', values);

  static deleteAdmin = ({ emailHash }) => axios.delete(`/admins/${emailHash}/`);

  static getAdminConfirmAccount = ({ authHash }) =>
    axios.get(`/admins/${authHash}/confirm_account`);

  static confirmAdminConfirmAccount = ({ authHash }) =>
    axios.post(`/admins/${authHash}/confirm_account`);

  static getAdminPermissions = emailHash => axios.get(`/adminpermissions/${emailHash}/`);

  static updateAdminPermissions = ({ emailHash, permissions = {} }) =>
    axios.put(`/adminpermissions/${emailHash}/`, permissions);

  static getTwoFactorQrCode = () =>
    axios.get('/2fa/', { headers: { 'Content-Type': 'image/jpeg' } });

  static enableTwoFactorAuth = token => axios.post('/2fa', { token });

  static disableTwoFactorAuth = ({ emailHash = null } = {}) => {
    let url = '/2fa/';
    if (emailHash) {
      url = `${url}${emailHash}/`;
    }
    return axios.delete(url);
  };

  static deleteUser = ({ id }) => axios.delete(`/users/${id}`);

  static deleteUsers = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'DELETE_USERS',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static inviteUser = ({ organisationId, first_name, last_name, email, comment, divisionId }) =>
    axios.post(`${apiUrlDrf}/organisations/${organisationId}/users`, {
      first_name,
      last_name,
      email,
      comment,
      division_id: divisionId,
    });

  static addMembersToGroup = ({ id, usersIds }) =>
    axios.post(`/groups/${id}/members`, { users: usersIds });

  static deleteMemberFromGroup = ({ id, userId }) =>
    axios.delete(`/groups/${id}/members/${userId}`);

  static getCircleMembers = ({ circleId, organisationId, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/circles/${circleId}/members/${queryStringParameters}`,
    );
  };

  static setEnableTokens = ({
    allUsers,
    excludeUsers,
    organisationId,
    users,
    filters,
    tokenValidity,
  }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'ENABLE_TOKEN',
        token_validity: tokenValidity,
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static setLoginPassword = ({ login_password, id } = {}) =>
    axios.put(`/users/${id}`, { login_password });

  static setLoginPasswords = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'SET_PASSWORD',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static setOneTimePasswordEmail = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'SET_OTP_EMAIL',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static setOneTimePasswordCSV = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'SET_OTP_CSV',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static enableUser = ({ id }) => axios.delete(`/users/${id}/disable`);

  static enableUsers = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'ENABLE_USERS',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static disableUser = ({ id }) => axios.put(`/users/${id}/disable`);

  static disableUsers = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'DISABLE_USERS',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static unblockLoginUsers = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'UNBLOCK_LOGIN',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static resendInvitation = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'RESEND_INVITATION_EMAIL',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static forcePasswordChange = ({ allUsers, excludeUsers, organisationId, users, filters }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.post(
      `${apiUrlDrf}/organisations/${organisationId}/users/bulk/${queryStringParameters}`,
      {
        operation: 'FORCE_RESET_PASSWORD',
        ...(allUsers ? { all_users: allUsers } : {}),
        ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
        ...(users?.length > 0 ? { users } : {}),
      },
    );
  };

  static setUserDivision = ({ organisationId, userId, divisionId }) =>
    axios.put(`${apiUrlDrf}/organisations/${organisationId}/users/${userId}/`, {
      division: divisionId,
    });

  static setUsersDivision = ({ organisationId, divisionId, allUsers, excludeUsers, users }) =>
    axios.post(`${apiUrlDrf}/organisations/${organisationId}/users/bulk/`, {
      operation: 'SET_DIVISION',
      ...(allUsers ? { all_users: allUsers } : {}),
      ...(excludeUsers?.length > 0 ? { exclude_users: excludeUsers } : {}),
      ...(users?.length > 0 ? { users } : {}),
      division_id: divisionId,
    });

  static getAllDomains = () => axios.get('/domains');

  static getPaginatedDomains = ({ organisationId, filters = {} }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);

    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/domains/${queryStringParameters}`,
    );
  };

  static createDomain = ({ organisationId, domainName }) =>
    axios.post('/domains/', { organisation_id: organisationId, domain: domainName });

  static deleteDomain = id => axios.delete(`/domains/${id}`);

  static getAllIntegrations = () => axios.get('/apikeys/');

  static getIntegration = id => axios.get(`/apikeys/${id}`);

  static deleteIntegration = id => axios.delete(`/apikeys/${id}`);

  static updateIntegration = ({ id, props }) => axios.put(`/apikeys/${id}`, props);

  static createIntegration = ({ organisationId, name, comment }) =>
    axios.post('/apikeys/', { organisation_id: organisationId, name, comment });

  static addAllowedGroupToIntegration = ({ id, groupsIds = [] }) =>
    axios.post(`/apikeys/${id}/allowed_groups/`, { group_ids: groupsIds });

  static removeAllowedGroupFromIntegration = ({ id, groupId }) =>
    axios.delete(`/apikeys/${id}/allowed_groups/${groupId}`);

  static getUser = id => axios.get(`/users/${id}`);

  static editUser = ({ id, first_name, last_name }) =>
    axios.put(`/users/${id}`, { first_name, last_name });

  static addComment = ({ id, comment }) => axios.put(`/users/${id}`, { comment });

  static updateSession = ({ id, connector_state }) =>
    axios.put(`/connectors/${id}`, { connector_state });

  static deleteSession = ({ id }) => axios.delete(`/connectors/${id}`);

  static importCSVUsers = ({ send_mail, file } = {}) =>
    oboe({
      url: apiUrl + '/users/csv',
      method: 'POST',
      body: { send_mail, file },
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    });

  static getSettings = ({ id }) => axios.get(`/settings/${id}`);

  static saveSettings = ({ id, settings }) => axios.put(`/settings/${id}`, { ...settings });

  static getEmailTemplates = () => axios.get('/templates/');

  static getEmailTemplate = ({ name, id }) => axios.get(`/templates/${name}/${id}`);

  static getPreviewTemplate = ({ name, template }) =>
    axios.post(`/template_preview/${name}`, { template });

  static saveEmailTemplate = ({ id, type, content }) =>
    axios.put(`/templates/${id}`, { type, content });

  static getAppSettings = ({ id }) => axios.get(`/globalappsettings/${id}`);

  static saveAppSettings = ({ id, values }) => axios.put(`/globalappsettings/${id}`, values);

  static savePassword = ({ old_password, new_password }) =>
    axios.put('/password', { old_password, new_password });

  static getPlatformSettings = ({ id }) => axios.get(`/platformappsettings/All/${id}`);

  static savePlatformSettings = ({ id, settings, platform }) =>
    axios.put(`/platformappsettings/${platform}/${id}`, { ...settings });

  static getAuditLog = ({ from, to }) =>
    axios.get(`/audit_log/?fromData=${from}&toDate=${to}&page=1`);

  static getLDAPSettings = ({ id }) => axios.get(`/ldapsettings/${id}`);

  static saveLDAPSettings = ({ id, settings }) => axios.post(`/ldapsettings/${id}`, settings);

  static getLDAPLogs = ({ id, skipEntries }) =>
    axios.get(`/ldapsync/${id}?sync_log_skip_entries=${skipEntries}`);

  static startSyncLDAPLogs = ({ id }) => axios.put(`/ldapsync/${id}`);

  static stopSyncLDAPLogs = ({ id }) => axios.delete(`/ldapsync/${id}`);

  static getArchiveListAdmins = ({ id }) => axios.get(`/archive/list_admins/${id}/`);

  static confirmMobilePhone = ({ email, pin }) =>
    axios.post('/register/confirm_mobile', { email, pin });

  static confirmEmail = ({ secret }) =>
    axios.post('/register/confirm_email', {
      secret,
      admin_confirmation_link: '/dashboard/#/confirm-admin?auth=',
    });

  static saveLogo = ({ formData, id }) =>
    axios.post(`/organisationlogo/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  static deleteLogo = ({ id }) => axios.delete(`/organisationlogo/${id}`);

  static generateFederationProfile = name => axios.post('/federationsettings/generate', { name });

  static getAllFederations = () => axios.get('/federationsettings');

  static downloadFederationProfile = () => axios.get('federationsettings/export');

  static toggleFederation = ({ value, id }) =>
    axios.put(`/federationsettings/${id}/`, { enabled: value });

  static refreshExternalFederation = ({ id }) => axios.get(`/federationsettings/${id}/refresh/`);

  static addFederationProfile = ({ formData }) =>
    axios.post(`/federationsettings/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  static getGeneralSettings = () => axios.get(`/generalsettings`);

  static updateVoIPGeneralSettings = payload =>
    axios.put('/generalsettings', {
      enable_voip_calls: payload.enableVoipCalls,
      ...(payload.enableVoipCalls ? { max_group_call_members: payload.maxGroupCallMembers } : {}),
    });

  static updateOnboardingBotGeneralSettings = payload =>
    axios.put('/generalsettings', {
      enable_onboarding_bot: payload.enableOnboardingBot,
      enable_admin_role_to_onboarding_bot: payload.enableAdminRoleToOnboardingBot,
      onboarding_bot_app_id: payload.onboardingBotAppId,
    });

  static getAllDivisions = ({ organisationId, filters = {} }) => {
    const queryStringParameters = getFormattedQueryStringParameters(filters);
    return axios.get(
      `${apiUrlDrf}/organisations/${organisationId}/divisions/${queryStringParameters}`,
    );
  };

  static addDivision = ({ organisationId, divisionName }) =>
    axios.post(`${apiUrlDrf}/organisations/${organisationId}/divisions/`, {
      name: divisionName,
    });

  static deleteDivision = ({ organisationId, divisionId }) =>
    axios.delete(`${apiUrlDrf}/organisations/${organisationId}/divisions/${divisionId}/`);
}

if (process.env.NODE_ENV === 'development') {
  AdminApi.deleteFederatedBackend = ({ id }) => axios.delete(`/federationsettings/${id}`);
}

export default AdminApi;
