import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import UserAbilities from 'components/UserAbilities/UserAbilities';

const useStyles = makeStyles(theme => ({
  userName: {
    color: theme.palette.primary.dark,
  },
  role: {
    marginTop: theme.spacing(0.5),
  },
}));

const NavBarUserInfo = ({ name, role, isSuperAdmin, isLoading }) => {
  const classes = useStyles();

  return (
    <Box py={2} px={3}>
      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Skeleton variant="text" width={150} height={24} />
          <Skeleton variant="text" width={50} height={16} />
          <Skeleton variant="text" width={100} height={19} />
        </Box>
      ) : (
        <Fragment>
          <div data-testid="username">
            <Typography variant="h4" className={classes.userName}>
              {name}
            </Typography>
          </div>
          <div className={classes.role} data-testid="role">
            <Typography variant="caption">{role}</Typography>
          </div>
          <UserAbilities superAdmin={isSuperAdmin} />
        </Fragment>
      )}
    </Box>
  );
};

NavBarUserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool,
};

export default memo(NavBarUserInfo);
