import { colors as MuiColors } from '@material-ui/core';
import colors from './colors';

const palette = {
  black: colors.black,
  white: colors.white,
  primary: {
    contrastText: colors.white,
    dark: colors.darkBlue,
    main: colors.mainBlue,
    border: colors.mediumGrey,
  },
  secondary: {
    contrastText: colors.white,
    main: colors.softGreen,
    bismark: colors.bismark,
    blue: colors.blue,
  },
  error: {
    contrastText: colors.white,
    main: colors.softRed,
  },
  text: {
    primary: MuiColors.blueGrey[900],
    secondary: colors.darkGrey,
    link: MuiColors.blue[600],
    dark: colors.darkBlue,
  },
  link: MuiColors.blue[800],
  icon: MuiColors.blueGrey[600],
  background: {
    default: colors.lightGray,
    paper: colors.white,
  },
  divider: MuiColors.grey[200],
};

export default palette;
