import { Box } from '@material-ui/core';
import HandleError from 'components/HandleError';

const GenericError = () => {
  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <HandleError withReload />
    </Box>
  );
};

GenericError.propTypes = {};

export default GenericError;
