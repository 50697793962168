import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import copy from 'clipboard-copy';

import { Drawer, List, ListSubheader, Popover, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { fetchReleaseHash, getReleaseHash } from 'features/users/usersSlice';

import usePermissions from 'hooks/usePermissions';

import NavBarUserInfoContainer from 'containers/NavBarUserInfoContainer';

import { Info as InfoIcon, FileCopyOutlined } from 'components/Icons';
import NavItem from 'components/NavItem';

import config from 'config';

import navConfig from './navConfig';
import { useSnackbar } from 'notistack';

const backgroundColor = '#f9f7f7';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: 256,
    top: 64,
    backgroundColor,
    height: 'calc(100% - 64px)',
  },
  menuContainer: {
    padding: 0,
    backgroundColor: theme.palette.white,
  },
  navigation: {
    overflowY: 'auto',
    flex: 1,
  },
  subheader: {
    fontSize: theme.typography.pxToRem(11),
    borderTop: `1px solid ${theme.palette.primary.border}`,
    borderBottom: `1px solid ${theme.palette.primary.border}`,
    padding: theme.spacing(0, 3),
    lineHeight: theme.typography.pxToRem(42),
    textTransform: 'uppercase',
  },
  navItem: {
    padding: theme.spacing(0.5, 2),
    width: 'auto',
  },
  release: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
    marginTop: 'auto',
  },
  releaseHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  releaseIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.bismark,
  },
  releaseHash: {
    wordBreak: 'break-all',
  },
  scrollbar: {
    // flex: 1,
    flexShrink: 0,
  },
}));

const NavBar = ({ open, onClose, onClick, className, isResponsive, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const releaseHash = useSelector(getReleaseHash);
  const { canView } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickVersion = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseVersion = () => {
    setAnchorEl(null);
  };

  const openVersion = Boolean(anchorEl);
  const idVersion = openVersion ? 'simple-popover' : undefined;

  useEffect(() => {
    dispatch(fetchReleaseHash);
  }, [dispatch]);

  const content = useMemo(() => {
    const renderNavItems = ({ items, subheader, key, ...rest }) => {
      return (
        <List className={classes.menuContainer} key={key}>
          {subheader ? (
            <ListSubheader className={classes.subheader} disableSticky>
              {t(subheader)}
            </ListSubheader>
          ) : null}
          {items
            .filter(item => canView(item.key))
            .map(item => reduceChildRoutes({ item, ...rest }))}
        </List>
      );
    };

    const reduceChildRoutes = ({ pathname, item, depth = 0 }) => {
      if (item.items) {
        const open = matchPath(pathname, {
          path: item.to,
          exact: false,
        });
        return (
          <NavItem
            depth={depth}
            Icon={item.icon}
            key={item.title}
            Label={item.label}
            open={Boolean(open)}
            title={t(item.title)}
            className={classes.navItem}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
            })}
          </NavItem>
        );
      }

      return (
        <NavItem
          depth={depth}
          to={item.to}
          en_to={item.en_to}
          de_to={item.de_to}
          Icon={item.icon}
          key={item.title}
          Label={item.label}
          title={t(item.title)}
          external={Boolean(item.external)}
          className={classes.navItem}
          onClick={onClick}
        />
      );
    };

    return (
      <div {...rest} className={clsx(classes.root, className)} data-testid="navbar">
        <NavBarUserInfoContainer />
        <nav className={classes.navigation}>
          {navConfig
            .filter(list => list.items.length)
            .map(list =>
              renderNavItems({
                items: list.items,
                subheader: list.subheader,
                pathname: location.pathname,
                key: list.subheader,
              }),
            )}
        </nav>

        <div className={classes.release} data-testid="version" onClick={handleClickVersion}>
          <div className={classes.releaseHeader}>
            <InfoIcon className={classes.releaseIcon} />
            {t('VERSION')}
          </div>
        </div>
        <Popover
          id={idVersion}
          open={openVersion}
          anchorEl={anchorEl}
          onClose={handleCloseVersion}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {releaseHash ? (
            <div className={classes.release} data-testid="release">
              <Tooltip title={t('CLICK_TO_COPY')}>
                <IconButton
                  onClick={() => {
                    const copyProccess = async () => {
                      await copy(releaseHash.backend_version);
                      enqueueSnackbar(t('RELEASE COPIED'));
                    };
                    copyProccess();
                  }}
                >
                  <FileCopyOutlined />
                </IconButton>
              </Tooltip>
              <div className={classes.releaseHeader}>{t('RELEASE')}</div>
              <div className={classes.releaseHash}>{releaseHash.backend_version}</div>
            </div>
          ) : null}
          <div className={classes.release} data-testid="version">
            <Tooltip title={t('CLICK_TO_COPY')}>
              <IconButton
                onClick={() => {
                  const copyProccess = async () => {
                    await copy(`v${config.version}`);
                    enqueueSnackbar(t('VERSION COPIED'));
                  };
                  copyProccess();
                }}
              >
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
            <div className={classes.releaseHeader}>{t('VERSION')}</div>
            <div className={classes.releaseHash}>v{config.version}</div>
          </div>
        </Popover>
      </div>
    );
  }, [
    rest,
    classes,
    className,
    t,
    idVersion,
    openVersion,
    anchorEl,
    releaseHash,
    canView,
    onClick,
    location.pathname,
    enqueueSnackbar,
  ]);

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: classes.paper,
      }}
      onClose={onClose}
      open={open}
      variant={isResponsive ? 'temporary' : 'persistent'}
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

NavBar.defaultProps = {
  className: null,
  onClose: null,
  open: false,
};

export default NavBar;
