import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getSelectedOrganisationId } from 'features/organisations/organisationsSlice';

import AdminApi from 'api/AdminApi';
import { getMyOrganisationId } from 'features/profile/profileSlice';
import { ALL_ORGANISATIONS_KEY } from '../../hooks/useOrganisationsSelectOptions';

const initialState = {
  isLoading: false,
  settings: null,
  emailTemplates: null,
  emailTemplate: null,
  previewTemplate: null,
  appSettings: null,
  platformSettings: null,
  ldapSettings: null,
  ldapLogs: null,
  listAdmins: null,
};

export const SETTINGS_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ERROR_400: 'error_400',
  ERROR_403: 'error_403',
  ERROR_404: 'error_404',
  ERROR_406: 'error_406',
  ERROR_409: 'error_409',
};

/*
SETTINGS OBJECT
  privacy_mode: "Internal and external"
  allow_user_reg: true
  allow_query: true
  sync_group_members_only: false
  send_read_receipts: true
  enable_user_login: true
  enable_webclient: true
  registration_token: "1234"
  require_registration_token: false
  messages_retention_period: 0
  connector_retention_period: 28
  archivable: false
*/

/*
EMAIL TEMPLATES object
[{
  "type": "admin_registration_email_address_verification",
  "label": "'Verify your Email address' Email sent to new Admins after registration"
}]
*/

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initLoading: (state, action) => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    setSettings: (state, action) => {
      const { settings } = action.payload;
      state.settings = settings;
    },
    setEmailTemplates: (state, action) => {
      const { emailTemplates } = action.payload;
      state.emailTemplates = emailTemplates;
    },
    setEmailTemplate: (state, action) => {
      const { emailTemplate } = action.payload;
      state.emailTemplate = emailTemplate;
    },
    setPreviewTemplate: (state, action) => {
      const { previewTemplate } = action.payload;
      state.previewTemplate = previewTemplate;
    },
    updateSettings: (state, action) => {
      const { settings } = action.payload;
      state.settings = { ...state.settings, ...settings };
    },
    setAppSettings: (state, action) => {
      const { appSettings } = action.payload;
      state.appSettings = appSettings;
    },
    setPlatformSettings: (state, action) => {
      const { platformSettings } = action.payload;
      state.platformSettings = platformSettings;
    },
    updatePlatformSettings: (state, action) => {
      const { platformSettings, platform } = action.payload;
      state.platformSettings = { ...state.platformSettings, [platform]: platformSettings };
    },
    setLDAPSettings: (state, action) => {
      const { ldapSettings } = action.payload;
      state.ldapSettings = ldapSettings;
    },
    setLDAPLogs: (state, action) => {
      const { ldapLogs, skipEntries } = action.payload;
      if (skipEntries === 0) {
        state.ldapLogs = ldapLogs;
      } else {
        state.ldapLogs = {
          ...state.ldapLogs,
          ...ldapLogs,
          sync_log: state.ldapLogs.sync_log.concat(ldapLogs.sync_log),
        };
      }
    },
    clearLDAPLogs: state => {
      state.ldapLogs = null;
    },
    setListAdmins: (state, action) => {
      const { listAdmins } = action.payload;
      state.listAdmins = listAdmins;
    },
  },
});

export const {
  initLoading,
  finishLoading,
  setSettings,
  updateSettings,
  setEmailTemplates,
  setEmailTemplate,
  setPreviewTemplate,
  setAppSettings,
  setPlatformSettings,
  updatePlatformSettings,
  setLDAPSettings,
  setLDAPLogs,
  clearLDAPLogs,
  setListAdmins,
} = settingsSlice.actions;

const getSettingsState = state => state.settings;

export const getIsLoadingSettings = createSelector([getSettingsState], state => state.isLoading);
export const getSettings = createSelector([getSettingsState], state => state.settings);
export const getEmailTemplates = createSelector([getSettingsState], state => state.emailTemplates);
export const getEmailTemplate = createSelector([getSettingsState], state => state.emailTemplate);
export const getPreviewTemplate = createSelector(
  [getSettingsState],
  state => state.previewTemplate,
);
export const getAppSettings = createSelector([getSettingsState], state => state.appSettings);
export const getPlatformSettings = createSelector(
  [getSettingsState],
  state => state.platformSettings,
);
export const getLDAPSettings = createSelector([getSettingsState], state => state.ldapSettings);
export const getLDAPLogs = createSelector([getSettingsState], state => state.ldapLogs);
export const getListAdmins = createSelector([getSettingsState], state => state.listAdmins);

export const fetchSettings = ({ id }) => async dispatch => {
  if (id === ALL_ORGANISATIONS_KEY) return SETTINGS_RESPONSES.OK;

  dispatch(initLoading());
  try {
    const { data: settings } = await AdminApi.getSettings({ id });
    dispatch(setSettings({ settings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const saveSettings = ({ settings }) => async (dispatch, getState) => {
  const state = getState();
  const id = getSelectedOrganisationId(state);
  try {
    await AdminApi.saveSettings({ id, settings });
    dispatch(updateSettings({ settings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        return SETTINGS_RESPONSES.ERROR_400;
      }
      if (status === 403) {
        return SETTINGS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return SETTINGS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return SETTINGS_RESPONSES.ERROR_409;
      }
    }
    return SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchEmailTemplates = async dispatch => {
  try {
    const { data: emailTemplates } = await AdminApi.getEmailTemplates();
    dispatch(setEmailTemplates({ emailTemplates: emailTemplates.templates }));
    return emailTemplates.templates;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchEmailTemplate = ({ name, id }) => async dispatch => {
  try {
    const { data: emailTemplate } = await AdminApi.getEmailTemplate({ name, id });
    dispatch(setEmailTemplate({ emailTemplate: emailTemplate.content }));
    return emailTemplate.content;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchPreviewTemplate = ({ name, template }) => async dispatch => {
  try {
    const { data: previewTemplate } = await AdminApi.getPreviewTemplate({ name, template });
    dispatch(
      setPreviewTemplate({ previewTemplate: previewTemplate.subject + previewTemplate.body }),
    );
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const saveEmailTemplate = ({ id, type, content }) => async dispatch => {
  try {
    const { data: emailTemplate } = await AdminApi.saveEmailTemplate({ id, type, content });
    dispatch(setEmailTemplate({ emailTemplate: emailTemplate.content }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchAppSettings = ({ id }) => async dispatch => {
  dispatch(initLoading());
  try {
    const { data: appSettings } = await AdminApi.getAppSettings({ id });
    dispatch(setAppSettings({ appSettings }));
    return appSettings;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const saveAppSettings = ({ id, values }) => async dispatch => {
  try {
    const { data: appSettings } = await AdminApi.saveAppSettings({ id, values });
    dispatch(setAppSettings({ appSettings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return SETTINGS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return SETTINGS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return SETTINGS_RESPONSES.ERROR_409;
      }
    }
    return SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchPlatformSettings = ({ id }) => async dispatch => {
  dispatch(initLoading());
  try {
    const { data: platformSettings } = await AdminApi.getPlatformSettings({ id });
    dispatch(setPlatformSettings({ platformSettings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const savePlatformSettings = ({ id, settings, platform }) => async dispatch => {
  try {
    const { data: platformSettings } = await AdminApi.savePlatformSettings({
      id,
      settings,
      platform: platform.toLowerCase(),
    });
    dispatch(updatePlatformSettings({ platformSettings, platform }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 403) {
        return SETTINGS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return SETTINGS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return data?.error ?? SETTINGS_RESPONSES.ERROR_409;
      }
      return data?.error ?? SETTINGS_RESPONSES.ERROR;
    }
    return SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchLDAPSettings = ({ id }) => async dispatch => {
  dispatch(initLoading());
  try {
    const { data: ldapSettings } = await AdminApi.getLDAPSettings({ id });
    dispatch(setLDAPSettings({ ldapSettings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const fetchLDAPLogs = ({ id, skipEntries }) => async dispatch => {
  try {
    const { data: ldapLogs } = await AdminApi.getLDAPLogs({ id, skipEntries });
    dispatch(setLDAPLogs({ ldapLogs, skipEntries }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const saveLDAPSettings = ({ id, settings }) => async dispatch => {
  try {
    await AdminApi.saveLDAPSettings({ id, settings });
    dispatch(setLDAPSettings({ ldapSettings: settings }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return SETTINGS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return SETTINGS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return SETTINGS_RESPONSES.ERROR_409;
      }
    }
    return SETTINGS_RESPONSES.ERROR;
  }
};

export const startSyncLDAPLogs = ({ id }) => async dispatch => {
  try {
    await AdminApi.startSyncLDAPLogs({ id });
    const { data: ldapLogs } = await AdminApi.getLDAPLogs({ id, skipEntries: 0 });
    dispatch(setLDAPLogs({ ldapLogs, skipEntries: 0 }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const stopSyncLDAPLogs = ({ id }) => async dispatch => {
  try {
    await AdminApi.stopSyncLDAPLogs({ id });
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    throw SETTINGS_RESPONSES.ERROR;
  }
};

export const fetchListAdmin = ({ id }) => async (dispatch, getState) => {
  let organisationId = id;
  if (!id || id === '') {
    const state = getState();
    organisationId = getMyOrganisationId(state);
  }
  dispatch(initLoading());
  try {
    const { data: listAdmins } = await AdminApi.getArchiveListAdmins({ id: organisationId });
    dispatch(setListAdmins({ listAdmins }));
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    dispatch(setListAdmins({ listAdmins: [] }));
    throw SETTINGS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const saveLogo = ({ formData, id }) => async dispatch => {
  try {
    await AdminApi.saveLogo({ formData, id });
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 400) {
        return SETTINGS_RESPONSES.ERROR_400;
      }
      if (status === 401) {
        return SETTINGS_RESPONSES.ERROR_401;
      }
      if (status === 403) {
        return SETTINGS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return SETTINGS_RESPONSES.ERROR_404;
      }
      if (status === 406) {
        return SETTINGS_RESPONSES.ERROR_406;
      }
      if (status === 409) {
        return SETTINGS_RESPONSES.ERROR_409;
      }
    }
    return SETTINGS_RESPONSES.ERROR;
  }
};

export const deleteLogo = ({ id }) => async dispatch => {
  try {
    await AdminApi.deleteLogo({ id });
    return SETTINGS_RESPONSES.OK;
  } catch (error) {
    return SETTINGS_RESPONSES.ERROR;
  }
};

export default settingsSlice.reducer;
