import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import theme from 'theme';

import 'theme/react-dates.css';
import 'theme/clearX.css';
import 'theme/snackbar.css';

export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
