import { withStyles } from '@material-ui/core';

const GlobalCss = withStyles({
  '@global': {
    '*:focus': {
      outline: 'none',
    },
  },
})(() => null);

export default GlobalCss;
