const colors = {
  white: '#fff',
  black: '#000',
  mainBlue: '#0062c2',
  blue: '#1e88e5',
  darkBlue: '#001346',
  darkGrey: '#798490',
  softRed: '#ff3b56',
  softGreen: '#00efc8',
  mediumGrey: '#eeeeee',
  lightGray: '#f4f4f4',
  veryLightGrey: '#f5f5f5',
  softGray: '#f5f6f8',
  gray98: '#fafafa',
  fieldGrey: '#eceff1',
  bismark: '#546e7a',
  darkestBlue: '#263238',
  orange: '#f57c00',
  pattensBlue: '#cfd8dc',
  aliceBlue: '#f4f6f8',
  aliceBlue2: '#eef0f1',
  mediumLightBlue: '#598FF5',
  lightLightBlue: '#B4CEFF',
  armourGrey2: '#D5D5D5',
  armourGrey3: '#EFEFEF',
  armourGrey4: '#F6F6F6',
};

export default colors;
