import { SvgIcon } from '@material-ui/core';

const AddUser = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.99935C12.3012 9.99935 14.1667 8.13387 14.1667 5.83268C14.1667 3.5315 12.3012 1.66602 10 1.66602C7.69881 1.66602 5.83333 3.5315 5.83333 5.83268C5.83333 8.13387 7.69881 9.99935 10 9.99935ZM10 8.33268C11.3807 8.33268 12.5 7.21339 12.5 5.83268C12.5 4.45197 11.3807 3.33268 10 3.33268C8.61929 3.33268 7.5 4.45197 7.5 5.83268C7.5 7.21339 8.61929 8.33268 10 8.33268Z"
    />
    <path d="M10 12.0827V13.7493C8.63252 13.7493 7.30978 13.2658 6.70207 13.0115C6.47899 12.9182 6.27854 12.8446 6.08483 12.7962C5.1105 12.5526 4.16667 13.2895 4.16667 14.2939V15.9757C4.16667 16.0747 4.18996 16.1292 4.20064 16.1468L4.20259 16.1499L4.20465 16.1506C4.87047 16.3566 6.47212 16.666 10 16.666V18.3327C6.40385 18.3327 4.61018 18.0207 3.71198 17.7427C2.82749 17.469 2.5 16.6488 2.5 15.9757V14.2939C2.5 12.2053 4.46281 10.6727 6.48906 11.1793C6.80013 11.2571 7.08842 11.3665 7.34534 11.474C7.89471 11.7039 8.96973 12.0827 10 12.0827Z" />
    <path d="M14.1667 11.666H15.8333V14.166H18.3333V15.8327H15.8333V18.3327H14.1667V15.8327H11.6667V14.166H14.1667V11.666Z" />
  </SvgIcon>
);

export default AddUser;
