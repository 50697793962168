import {
  Dashboard,
  StarBorder,
  PersonOutline,
  ListAlt,
  BusinessOutlined,
  DeviceHub,
  Lock,
  Settings,
  VpnKey,
  PlaylistAddCheck,
  MailOutline,
  Colorize,
  DescriptionOutlined,
  HelpOutlined,
  CheckOutlined,
  Sync,
  InsertDriveFileOutlined,
  BarChart,
  GroupWorkOutlined,
  Federation,
} from 'components/Icons';

const ADMINISTRATOR_PORTAL_BASE_PATHNAME = '/static/documentation/Teamwire-Dashboard-Documentation';

const navConfig = [
  {
    subheader: 'HEADER_MENU_1',
    items: [
      {
        key: 'SECTION_OVERVIEW',
        title: 'OVERVIEW',
        to: '/overview',
        icon: Dashboard,
      },
      {
        title: 'STATISTICS',
        to: '/statistics',
        icon: BarChart,
      },
      {
        key: 'SECTION_AUDIT_LOG',
        title: 'SECTION_AUDIT_LOG',
        to: '/audit-log',
        icon: CheckOutlined,
      },
    ],
  },
  {
    subheader: 'MANAGE',
    items: [
      {
        key: 'SECTION_ADMINS',
        title: 'admins',
        to: '/admins',
        icon: StarBorder,
      },
      {
        key: 'SECTION_USERS',
        title: 'USERS',
        to: '/users',
        icon: PersonOutline,
      },
      {
        key: 'SECTION_GROUPS',
        title: 'circles',
        to: '/circles',
        icon: GroupWorkOutlined,
      },
      {
        key: 'SECTION_DOMAINS',
        title: 'DOMAINS',
        to: '/domains',
        icon: ListAlt,
      },
      {
        key: 'SECTION_ORGANISATIONS',
        title: 'ORGANISATIONS',
        to: '/organisations',
        icon: BusinessOutlined,
      },
      {
        key: 'teamwire_federation',
        title: 'teamwire_federation',
        to: '/federation',
        icon: Federation,
      },
      {
        key: 'SECTION_APP_INTEGRATIONS',
        title: 'APP_INTEGRATIONS',
        to: '/integrations',
        icon: DeviceHub,
      },
    ],
  },
  {
    subheader: 'HEADER_MENU_3',
    items: [
      {
        key: 'SECTION_PRIVACY_SETTINGS',
        title: 'PRIVACY_SETTINGS',
        to: '/settings/privacy',
        icon: Lock,
      },
      {
        key: 'SECTION_RETENTION_SETTINGS',
        title: 'RETENTION_SETTINGS',
        to: '/settings/retention',
        icon: Settings,
      },
      {
        key: 'SECTION_LOGIN_REGISTRATION',
        title: 'LOGIN_REGISTRATION',
        to: '/settings/login-registration',
        icon: VpnKey,
      },
      {
        key: 'APP_PERMISSIONS',
        title: 'APP_PERMISSIONS',
        to: '/settings/permissions',
        icon: PlaylistAddCheck,
      },
      {
        key: 'STYLE_SETTINGS',
        title: 'STYLE_SETTINGS',
        to: '/settings/style',
        icon: Colorize,
      },
      {
        key: 'EMAIL_TEMPLATES',
        title: 'EMAIL_TEMPLATES',
        to: '/settings/email',
        icon: MailOutline,
      },
      {
        key: 'LDAP_INTEGRATION',
        title: 'LDAP_INTEGRATION',
        to: '/settings/ldap',
        icon: Sync,
      },
      {
        key: 'ARCHIVES',
        title: 'ARCHIVES',
        to: '/settings/archives',
        icon: InsertDriveFileOutlined,
      },
    ],
  },
  {
    subheader: 'HEADER_MENU_4',
    items: [
      {
        title: 'DOCUMENTATION',
        en_to: `${ADMINISTRATOR_PORTAL_BASE_PATHNAME}_EN.pdf`,
        de_to: `${ADMINISTRATOR_PORTAL_BASE_PATHNAME}_DE.pdf`,
        icon: DescriptionOutlined,
        external: true,
      },
      {
        title: 'SUPPORT_PORTAL',
        en_to: 'https://support.teamwire.eu/en',
        de_to: 'https://support.teamwire.eu/de',
        icon: HelpOutlined,
        external: true,
      },
    ],
  },
];

const getDocumentationUrl = () =>
  `${ADMINISTRATOR_PORTAL_BASE_PATHNAME}_${
    window.localStorage.getItem('i18nextLng').toUpperCase() || 'EN'
  }.pdf`;

export default navConfig;
export { getDocumentationUrl };
