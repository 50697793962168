import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import { showHttpError } from 'features/httpError/httpErrorSlice';
import { getMyOrganisationId } from 'features/profile/profileSlice';
import { isCancel } from 'axios';
import moment from 'moment';

export const USER_STATES = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
};

export const USERS_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
  ERROR_403: 'error_403',
  ERROR_404: 'error_404',
  ERROR_409: 'error_409',
};

export const USER_INVITE_RESPONSES = {
  OK: 'ok',
  GENERIC_ERROR: 'generic_error',
  ERROR_401: 'error_401',
  ERROR_402: 'error_402',
  ERROR_404: 'error_404',
  ERROR_406: 'error_406',
  ERROR_409: 'error_409',
  ERROR_204: 'error_204',
};

/*
USER MODEL
{
  origin: 'Native',
  comment: 'lorem ipsum',
  confirmed: false,
  first_name: 'NAME',
  origin_id: null,
  last_name: 'LAST',
  token_validity: null,
  user_state: 'Enabled',
  organisation_id: 5,
  joined: 1579274072,
  email: 'blabla@domain.com',
  registration_token: null,
  last_usage: 0,
  id: 'HAASH',
  login_password_set: false
  block_login: false,
  federation: null
}
*/

const usersAdapter = createEntityAdapter({
  selectId: user => user.id,
});

export const initialFilters = {
  block_login: null,
  confirmed: null,
  created_after: null,
  created_before: null,
  last_usage_after: null,
  last_usage_before: null,
  limit: 10,
  offset: 0,
  ordering: 'last_name',
  origin: null,
  search: null,
  status: null,
  division_id: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({
    isLoading: false,
    isDownloadingCsvData: false,
    releaseHash: null,
    lastFetch: undefined,
    inviteUserLoading: false,
    progressingUsers: 0,
    count: 0,
    filters: initialFilters,
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    initCsvDownload: state => {
      state.isDownloadingCsvData = true;
    },
    finishCsvDownload: state => {
      state.isDownloadingCsvData = false;
    },
    updateUsers(state, action) {
      const { addedUsers = [], deletedUsersIds = [] } = action.payload;

      state.lastFetch = new Date().toUTCString();

      usersAdapter.setAll(state, addedUsers);
      usersAdapter.removeMany(state, deletedUsersIds);
    },
    updateUser: (state, action) => {
      const { id, props = {} } = action.payload;
      usersAdapter.upsertOne(state, { id, ...props });
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    updateReleaseHash: (state, action) => {
      const { releaseHash } = action.payload;
      state.releaseHash = releaseHash;
    },
    userInviteRequest: state => {
      state.inviteUserLoading = true;
    },
    userInviteFinished: state => {
      state.inviteUserLoading = false;
    },
    userInviteSuccess: (state, action) => {
      const { user } = action.payload;
      state.inviteUserLoading = false;
      usersAdapter.addOne(state, user);
    },
    userInviteFailed: state => {
      state.inviteUserLoading = false;
    },
    updateProgressingUsers: (state, action) => {
      const { progressingUsers } = action.payload;
      state.progressingUsers = progressingUsers;
    },
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const {
  initLoading,
  finishLoading,
  initCsvDownload,
  finishCsvDownload,
  updateUsers,
  updateUser,
  updateReleaseHash,
  userInviteRequest,
  userInviteSuccess,
  userInviteFinished,
  userInviteFailed,
  updateProgressingUsers,
  updateCount,
  updateFilters,
} = usersSlice.actions;

function checkFiltersToAddUserToCsv(user, filters) {
  let addToCsv = true;

  if (filters.block_login !== null) {
    addToCsv = JSON.parse(filters.block_login) === user.block_login;

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.confirmed !== null) {
    addToCsv = JSON.parse(filters.confirmed) === user.confirmed;

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.origin !== null) {
    addToCsv = filters.origin === user.origin;

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.status !== null) {
    addToCsv = filters.status === user.user_state;

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.search !== null) {
    const lowerCaseSearch = filters.search.toLowerCase();

    addToCsv =
      user.first_name?.toLowerCase().includes(lowerCaseSearch) ||
      user.last_name?.toLowerCase().includes(lowerCaseSearch) ||
      user.email?.toLowerCase().includes(lowerCaseSearch);

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.last_usage_before !== null && filters.last_usage_after !== null) {
    addToCsv = moment(user.last_usage * 1000).isBetween(
      moment(filters.last_usage_after),
      moment(filters.last_usage_before),
      undefined,
      '[]',
    );

    if (!addToCsv) {
      return false;
    }
  }

  if (filters.created_before !== null && filters.created_after !== null) {
    addToCsv = moment(user.joined * 1000).isBetween(
      moment(filters.created_after),
      moment(filters.created_before),
      undefined,
      '[]',
    );

    if (!addToCsv) {
      return false;
    }
  }

  return addToCsv;
}

export const getUsersCsvData = async (dispatch, getState) => {
  dispatch(initCsvDownload());

  const promise = new Promise((resolve, reject) => {
    const csvUsers = [];
    const {
      organisations,
      users: { filters },
    } = getState();

    AdminApi.getAllUsers()
      .node('{user}', ({ user }) => {
        const addUserToCsv = checkFiltersToAddUserToCsv(user, filters);

        if (!addUserToCsv) {
          return;
        }

        const organisationId = organisations.selectedOrganisationId;
        if (organisationId === 'ALL_ORGANISATIONS') {
          csvUsers.push(user);
        } else {
          if (user.organisation_id === organisationId) {
            csvUsers.push(user);
          }
        }
      })
      .node('{finished}', () => {
        const sortedCsvUsers = csvUsers.sort((userA, userB) => {
          let sortFactor = 1;
          if (filters.ordering.startsWith('-')) {
            sortFactor = -1;
          }

          if (filters.ordering.includes('first_name')) {
            return (
              userA.first_name.toUpperCase().localeCompare(userB.first_name.toUpperCase()) *
              sortFactor
            );
          }

          if (filters.ordering.includes('last_name')) {
            return (
              userA.last_name?.toUpperCase().localeCompare(userB.last_name?.toUpperCase()) *
              sortFactor
            );
          }

          if (filters.ordering.includes('email')) {
            return userA.email.toUpperCase().localeCompare(userB.email.toUpperCase()) * sortFactor;
          }

          if (filters.ordering.includes('joined')) {
            return moment(userA.joined).isAfter(moment(userB.joined)) * sortFactor;
          }

          if (filters.ordering.includes('last_usage')) {
            return moment(userA.last_usage).isAfter(moment(userB.last_usage)) * sortFactor;
          }

          if (filters.ordering.includes('origin')) {
            return (
              userA.origin.toUpperCase().localeCompare(userB.origin.toUpperCase()) * sortFactor
            );
          }

          if (filters.ordering.includes('confirmed')) {
            return (
              userA.confirmed
                .toString()
                .toUpperCase()
                .localeCompare(userB.confirmed.toString().toUpperCase()) * sortFactor
            );
          }

          if (filters.ordering.includes('block_login')) {
            return (
              userA.block_login
                .toString()
                .toUpperCase()
                .localeCompare(userB.block_login.toString().toUpperCase()) * sortFactor
            );
          }

          if (filters.ordering.includes('user_state')) {
            return (
              userA.user_state.toUpperCase().localeCompare(userB.user_state.toUpperCase()) *
              sortFactor
            );
          }

          return 0;
        });

        resolve(sortedCsvUsers);
      })
      .fail(error => {
        reject(error);
      });
  });

  try {
    return await promise;
  } catch (error) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishCsvDownload());
  }
};

// const PAGE_SIZE_PERFORM_STREAM = 10000;
export const fetchUsers = async (dispatch, getState) => {
  const {
    organisations,
    profile,
    users: { filters },
  } = getState();

  let organisationId =
    organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
      ? 'all'
      : organisations.selectedOrganisationId;

  if (organisationId === null) {
    organisationId = profile.data.organisation_id;
  }

  dispatch(initLoading());

  try {
    const {
      data: { count, results },
    } = await AdminApi.getPaginatedUsers({ filters, organisationId });

    dispatch(updateCount(count));
    dispatch(updateUsers({ addedUsers: results }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    if (!isCancel(error)) {
      throw error;
    }
  } finally {
    dispatch(finishLoading());
  }
};

export const fetchReleaseHash = async dispatch => {
  try {
    const resp = await AdminApi.getReleaseHash();
    dispatch(updateReleaseHash({ releaseHash: resp.data }));
  } catch (error) {
    dispatch(showHttpError());
  }
};

export const deleteUser = userId => async dispatch => {
  try {
    await AdminApi.deleteUser({ id: userId });
    dispatch(fetchUsers);
    return USERS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return USERS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return USERS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return USERS_RESPONSES.ERROR_409;
      }
    }
    return USERS_RESPONSES.ERROR;
  }
};

export const deleteUsers = ({ allUsers, excludeUsers, users }) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { organisations, users: stateUsers } = getState();

      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      const { data } = await AdminApi.deleteUsers({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      });

      resolve(data[0].total_no_of_users);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 403) {
          reject(USERS_RESPONSES.ERROR_403);
        }
        if (status === 404) {
          reject(USERS_RESPONSES.ERROR_404);
        }
        if (status === 409) {
          reject(USERS_RESPONSES.ERROR_409);
        }
      }
      reject(USERS_RESPONSES.ERROR);
    }
  });
};

export const doUserInvite = ({
  organisationId,
  first_name,
  last_name,
  email,
  comment,
  divisionId,
}) => async dispatch => {
  try {
    dispatch(userInviteRequest());
    const { data, status } = await AdminApi.inviteUser({
      organisationId: organisationId === 'ALL_ORGANISATIONS' ? 'all' : organisationId,
      first_name,
      last_name,
      email,
      comment,
      divisionId,
    });
    dispatch(userInviteFinished());

    if (status === 204) {
      dispatch(userInviteFailed());
      return { status: USER_INVITE_RESPONSES.ERROR_204 };
    }

    return { status: USER_INVITE_RESPONSES.OK, user: data };
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        dispatch(userInviteFailed());
        return { status: USER_INVITE_RESPONSES.ERROR_401 };
      }
      if (status === 402) {
        dispatch(userInviteFailed());
        return { status: USER_INVITE_RESPONSES.ERROR_402 };
      }
      if (status === 404) {
        dispatch(userInviteFailed());
        return { status: USER_INVITE_RESPONSES.ERROR_404 };
      }
      if (status === 406) {
        dispatch(userInviteFailed());
        return { status: USER_INVITE_RESPONSES.ERROR_406 };
      }
      if (status === 409) {
        dispatch(userInviteFailed());
        return { status: USER_INVITE_RESPONSES.ERROR_409 };
      }
    }
    dispatch(userInviteFailed());
    return { status: USER_INVITE_RESPONSES.GENERIC_ERROR };
  }
};

export const setEnableTokens = ({ allUsers, excludeUsers, users, token_validity }) => (
  dispatch,
  getState,
) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.setEnableTokens({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
        tokenValidity: token_validity,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(user => {
            const { id, registration_token, token_validity } = user;
            const props = {
              registration_token,
              token_validity,
            };
            dispatch(updateUser({ id, props }));
          });
        }
        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setLoginPassword = ({ login_password, id }) => async dispatch => {
  try {
    await AdminApi.setLoginPassword({ login_password, id });
    const props = {
      login_password_set: true,
    };
    dispatch(updateUser({ id, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return false;
  }
};

export const setLoginPasswords = ({ allUsers, excludeUsers, users }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.setLoginPasswords({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(user => {
            const { id, login_password_set } = user;
            const props = {
              login_password_set,
            };
            dispatch(updateUser({ id, props }));
          });
        }
        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setOneTimePasswordEmail = ({ allUsers, excludeUsers, users }) => (
  dispatch,
  getState,
) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.setOneTimePasswordEmail({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(user => {
            const { id, otp_email_sent } = user;
            const props = {
              otp_email_sent,
            };
            dispatch(updateUser({ id, props }));
          });
        }
        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setOneTimePasswordCSV = ({ allUsers, excludeUsers, users }) => (
  dispatch,
  getState,
) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.setOneTimePasswordCSV({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);
        dispatch(updateProgressingUsers({ progressingUsers: 0 }));

        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const enableUser = userId => async dispatch => {
  try {
    await AdminApi.enableUser({ id: userId });
    const props = {
      user_state: 'Enabled',
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 402) {
        return USERS_RESPONSES.ERROR_402;
      }
      if (status === 403) {
        return USERS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return USERS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return USERS_RESPONSES.ERROR_409;
      }
    }
    return USERS_RESPONSES.ERROR;
  }
};

export const enableUsers = ({ users, allUsers, excludeUsers }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.enableUsers({
        users: users?.map(({ id }) => id),
        allUsers,
        excludeUsers,
        organisationId,
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(changedUser => {
            const { id } = changedUser;
            const props = {
              user_state: 'Enabled',
            };
            dispatch(updateUser({ id, props }));
          });
        }
        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const disableUser = userId => async dispatch => {
  try {
    await AdminApi.disableUser({ id: userId });
    const props = {
      user_state: 'Disabled',
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 403) {
        return USERS_RESPONSES.ERROR_403;
      }
      if (status === 404) {
        return USERS_RESPONSES.ERROR_404;
      }
      if (status === 409) {
        return USERS_RESPONSES.ERROR_409;
      }
    }
    return USERS_RESPONSES.ERROR;
  }
};

export const disableUsers = ({ allUsers, excludeUsers, users }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.disableUsers({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(user => {
            const { id } = user;
            const props = {
              user_state: 'Disabled',
            };
            dispatch(updateUser({ id, props }));
          });
        }

        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const unblockLoginUsers = ({ allUsers, excludeUsers, users }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.unblockLoginUsers({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        if (responseUsers.length > 0) {
          responseUsers.forEach(user => {
            const { id } = user;
            const props = {
              block_login: false,
            };
            dispatch(updateUser({ id, props }));
          });
        }

        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const resendInvitation = ({ allUsers, excludeUsers, users }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.resendInvitation({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const forceResetPassword = ({ allUsers, excludeUsers, users }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { organisations, users: stateUsers } = getState();

    try {
      const organisationId =
        organisations.selectedOrganisationId === 'ALL_ORGANISATIONS'
          ? 'all'
          : organisations.selectedOrganisationId;

      AdminApi.forcePasswordChange({
        allUsers,
        excludeUsers,
        organisationId,
        users: users?.map(({ id }) => id),
        filters: stateUsers.filters,
      }).then(response => {
        const responseUsers = response.data.filter(({ user }) => user).map(({ user }) => user);

        resolve(responseUsers);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const setUserDivision = ({ organisationId, userId, divisionId }) => async dispatch => {
  const { data: props } = await AdminApi.setUserDivision({ organisationId, userId, divisionId });
  dispatch(updateUser({ id: userId, props }));
  return USERS_RESPONSES.OK;
};

export const setUsersDivision = ({
  organisationId,
  divisionId,
  allUsers,
  excludeUsers,
  users,
}) => async (dispatch, getState) => {
  dispatch(initLoading());
  try {
    await AdminApi.setUsersDivision({
      organisationId:
        organisationId === 'ALL_ORGANISATIONS' ? getMyOrganisationId(getState()) : organisationId,
      divisionId,
      allUsers,
      excludeUsers,
      users: users?.map(user => (user?.id ? user.id : user)),
    });
    dispatch(fetchUsers);
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  } finally {
    dispatch(finishLoading());
  }
};

export const fetchUser = id => async (dispatch, getState) => {
  try {
    const { data: props } = await AdminApi.getUser(id);
    dispatch(updateUser({ props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

export const editUser = ({ userId, first_name, last_name }) => async dispatch => {
  try {
    await AdminApi.editUser({ id: userId, first_name, last_name });
    const props = {
      first_name: first_name,
      last_name: last_name,
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

export const addComment = ({ userId, comment }) => async dispatch => {
  try {
    await AdminApi.addComment({ id: userId, comment });
    const props = {
      comment,
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

// NOTE: deprecated since api v15, maybe it will be restored in the near future
export const clearPassword = ({ userId }) => async dispatch => {
  try {
    await AdminApi.setLoginPassword({ login_password: '', id: userId });
    const props = {
      login_password_set: false,
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

export const updateSession = ({ userId, sessionId, connector_state }) => async (
  dispatch,
  getState,
) => {
  try {
    await AdminApi.updateSession({ id: sessionId, connector_state });
    const state = getState();
    const { entities } = state.users;
    const user = entities[userId];
    const indexOfSession = user.connectors.findIndex(item => item.udid_hash === sessionId);
    const connectors = [...user.connectors];
    connectors[indexOfSession] = { ...connectors[indexOfSession], ...{ connector_state } };
    const props = {
      connectors,
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

export const deleteSession = ({ userId, sessionId }) => async (dispatch, getState) => {
  try {
    await AdminApi.deleteSession({ id: sessionId });
    const state = getState();
    const { entities } = state.users;
    const user = entities[userId];
    const connectors = [...user.connectors.filter(item => item.udid_hash !== sessionId)];
    const props = {
      connectors,
    };
    dispatch(updateUser({ id: userId, props }));
    return USERS_RESPONSES.OK;
  } catch (error) {
    return USERS_RESPONSES.ERROR;
  }
};

export const importCSVUsers = ({ send_mail, file }) => dispatch => {
  return new Promise((resolve, reject) => {
    try {
      const results = {
        total_no_of_users: null,
        users: [],
        msgs: [],
        errors: [],
        invalid_csv: false,
      };

      AdminApi.importCSVUsers({ send_mail, file })
        .node('{invalid_csv}', data => {
          results.invalid_csv = data.invalid_csv;
        })
        .node('{total_no_of_users}', data => {
          results.total_no_of_users = data.total_no_of_users;
        })
        .node('{msg}', data => {
          results.msgs.push(data.msg);
        })
        .node('{error}', data => {
          results.errors.push(data.error);
        })
        .node('{user}', data => {
          results.users.push(data.user);
        })
        .done(() => {
          if (!results.invalid_csv && !results.errors.length) {
            dispatch(fetchUsers);
          }
          resolve(results);
        })
        .fail(response => {
          reject(response);
        });
    } catch (error) {
      reject(error);
    }
  });
};

const getUsersState = state => state.users;

export const usersGlobalSelector = usersAdapter.getSelectors(getUsersState);
export const getIsLoading = createSelector([getUsersState], usersState => usersState.isLoading);
export const getIsDownloadingCsvData = createSelector(
  [getUsersState],
  usersState => usersState.isDownloadingCsvData,
);
export const getReleaseHash = createSelector([getUsersState], usersState => usersState.releaseHash);
export const getUsersList = usersGlobalSelector.selectAll;

export const getProgressingUsers = createSelector(
  [getUsersState],
  usersState => usersState.progressingUsers,
);

export const getUsersListByOrganisation = createSelector(
  [usersGlobalSelector.selectAll, (_, organisationId) => parseInt(organisationId)],
  (users, organisationId) => {
    let list = users;
    if (organisationId) {
      list = users.filter(admin => admin.organisation_id === organisationId);
    }
    return list.map(o => ({
      ...o,
      disabled: Boolean(o.federation),
    }));
  },
);
export const getPendingUsers = createSelector(
  [usersGlobalSelector.selectAll, (_, organisationId) => parseInt(organisationId)],
  (users, organisationId) => {
    let list;
    if (organisationId) {
      list = users.filter(
        admin => admin.organisation_id === organisationId && admin.last_usage === 0,
      );
    } else {
      list = users.filter(admin => admin.last_usage === 0);
    }
    return list.map(o => ({ ...o, disabled: Boolean(o.federation) }));
  },
);
export const getUserById = usersGlobalSelector.selectById;

export const getInviteUserLoading = createSelector(
  [getUsersState],
  usersState => usersState.inviteUserLoading,
);

export const getUsersCount = state => state.users.count;

export const getFilters = state => state.users.filters;

export default usersSlice.reducer;
