import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import en from 'i18n/en.json';
import de from 'i18n/de.json';

const supportedLanguages = ['en', 'de'];
const browserLanguage = navigator.language.slice(0, 2);
const lang = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

if (lang !== 'en') {
  // eslint-disable-next-line
  require(`moment/locale/${lang}`);
  moment.locale(lang);
} else {
  require(`moment/locale/en-gb`);
  moment.locale('en-gb');
}

const resources = {
  en: { translation: en },
  de: { translation: de },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: lang,
    debug: process.env.REACT_APP_DEBUG === 'enabled',
    // initImmediate: false,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      defaultTransParent: 'span',
      wait: true,
    },
  });

export const changeI18N = preferredLanguage => {
  i18n.changeLanguage(preferredLanguage.toLowerCase());
};

export default i18n;
