import { useCallback, useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from 'components/Icons';

import { rgba } from 'polished';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: theme.spacing(1.25, 1),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: theme.typography.pxToRem(-0.05),
    width: '100%',
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.text.secondary,
    },
  },
  buttonLeaf: {
    padding: theme.spacing(1.25, 1),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: theme.typography.pxToRem(-0.05),
    width: '100%',
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
    backgroundColor: rgba(theme.palette.text.secondary, 0.1),
    '&:hover': {
      backgroundColor: rgba(theme.palette.text.secondary, 0.1),
    },
  },
}));

const NavItem = ({
  title,
  to,
  en_to,
  de_to,
  depth,
  children,
  Icon,
  className,
  open: openProp,
  Label,
  external,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const lang = i18next.language;

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);

  const style = useMemo(() => {
    const paddingLeft = depth > 0 ? 32 + 8 * depth : 8;
    return { paddingLeft };
  }, [depth]);

  const IconComponent = useMemo(() => (open ? ExpandLessIcon : ExpandMoreIcon), [open]);

  if (children) {
    return (
      <ListItem {...rest} className={clsx(classes.item, className)} disableGutters key={title}>
        <Button className={classes.button} onClick={handleToggle} style={style} disableRipple>
          {Icon ? <Icon className={classes.icon} /> : null}
          {title}
          <IconComponent className={classes.expandIcon} color="inherit" />
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  const linkProps = {};

  if (external) {
    linkProps.href = lang === 'en' ? en_to : de_to;
    linkProps.target = '_blank';
    linkProps.rel = 'noopener noreferrer';
  } else {
    linkProps.to = to;
    linkProps.component = RouterLink;
    linkProps.exact = true;
    linkProps.activeClassName = classes.active;
  }
  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters key={title}>
      <Button
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        style={style}
        disableRipple
        {...linkProps}
      >
        {Icon ? <Icon className={classes.icon} /> : null}
        {title}
        {Label ? (
          <span className={classes.label}>
            <Label />
          </span>
        ) : null}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  en_to: PropTypes.string,
  de_to: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  Label: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  open: PropTypes.bool,
};

NavItem.defaultProps = {
  open: false,
  children: null,
  className: null,
  to: null,
  en_to: null,
  de_to: null,
  Icon: null,
  Label: null,
};

export default NavItem;
