import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsSuperAdmin, getPermissions } from 'features/profile/profileSlice';

const defaultConditions = {
  needSuperAdmin: false,
};

const usePermissions = () => {
  const iAmSuperAdmin = useSelector(getIsSuperAdmin);
  const adminPermissions = useSelector(getPermissions);

  const canEdit = useCallback(
    ({ conditions, entity = null, permissions = null } = {}) => {
      const actualConditions = { ...defaultConditions, ...conditions };
      const { needSuperAdmin } = actualConditions;

      const defaultPermissions = () => {
        if (permissions) {
          const neededPermissions = permissions.every(key => adminPermissions[key]);
          if (!neededPermissions) {
            return false;
          }
        }

        if (needSuperAdmin && !iAmSuperAdmin) {
          return false;
        }

        if (iAmSuperAdmin) {
          return true;
        }

        return true;
      };

      const permissionsByEntity = () => {
        if (entity) {
          const { key, value } = entity;
          switch (key) {
            case 'admin':
              return (!value?.super_admin && !iAmSuperAdmin) || iAmSuperAdmin;
            case 'group':
              return value?.origin === 'Native';
            default:
              return true;
          }
        }
        return true;
      };
      return defaultPermissions() && permissionsByEntity();
    },
    [adminPermissions, iAmSuperAdmin],
  );

  const canView = useCallback(
    key => {
      switch (key) {
        case 'SECTION_AUDIT_LOG':
          return adminPermissions.allow_view_audit_log;
        case 'SECTION_USERS':
          return adminPermissions.allow_view_users;
        case 'SECTION_GROUPS':
          return adminPermissions.allow_view_groups;
        case 'SECTION_APP_INTEGRATIONS':
          return adminPermissions.allow_view_api_keys;
        case 'SECTION_ADMINS':
          return adminPermissions.allow_view_admins;
        case 'SECTION_DOMAINS':
          return adminPermissions.allow_view_domains;
        case 'SECTION_PRIVACY_SETTINGS':
        case 'SECTION_RETENTION_SETTINGS':
        case 'SECTION_LOGIN_REGISTRATION':
        case 'APP_PERMISSIONS':
        case 'STYLE_SETTINGS':
        case 'EMAIL_TEMPLATES':
        case 'ARCHIVES':
          return adminPermissions.allow_view_settings;
        case 'LDAP_INTEGRATION':
          return adminPermissions.allow_view_settings && adminPermissions.allow_manage_ldap_sync;
        case 'SECTION_ORGANISATIONS':
        case 'teamwire_federation':
          return iAmSuperAdmin;
        default:
          return true;
      }
    },
    [
      adminPermissions.allow_manage_ldap_sync,
      adminPermissions.allow_view_api_keys,
      adminPermissions.allow_view_admins,
      adminPermissions.allow_view_audit_log,
      adminPermissions.allow_view_domains,
      adminPermissions.allow_view_groups,
      adminPermissions.allow_view_settings,
      adminPermissions.allow_view_users,
      iAmSuperAdmin,
    ],
  );

  return useMemo(
    () => ({
      iAmSuperAdmin,
      canEdit,
      canView,
    }),
    [iAmSuperAdmin, canEdit, canView],
  );
};

export default usePermissions;
