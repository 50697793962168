import sortBy from 'lodash/sortBy';
import { createSlice, createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import AdminApi from 'api/AdminApi';

import { groupsGlobalSelector } from 'features/groups/groupsSlice';
import { showHttpError } from 'features/httpError/httpErrorSlice';

import sortComparerBy from 'utils/sortComparerBy';
import cleanString from 'utils/cleanString';

export const INTEGRATION_RESPONSES = {
  OK: 'ok',
  ERROR: 'error',
};

/*
INTEGRATION OBJECT:
  {
    "comment": null,
    "updated_at": "2019-10-11T11:08:58.370Z",
    "allowed_groups": [
      {
        "created_at": "2019-10-11T11:08:58.256Z",
        "id": 27028,
        "title": "1chat"
      }
    ],
    "id": 130,
    "organisation_id": 134,
    "name": "Echo",
    "created_at": "2019-03-15T14:43:48.041Z",
    "state": "Enabled",
    "secret": "G+BrT+lQthdE/SbfjaLZO70FfQoSRxzB",
    "restrict_visibility": false,
    "appid": "754eff310caa0a0bb1707076ac352d7e2123c5801ea4ef953b54c77fdeea8203426c0e54b70c395f970290b22c82ca87"
  }

*/

const integrationsAdapter = createEntityAdapter({
  selectId: integration => integration.id,
  sortComparer: sortComparerBy('id'),
});

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsAdapter.getInitialState({
    isLoading: false,
    integrationsLoading: [],
    lastUpdated: null,
  }),
  reducers: {
    initLoading: state => {
      state.isLoading = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    initLoadingIntegration: (state, action) => {
      const id = String(action.payload.id);
      if (!state.integrationsLoading.includes(id)) {
        state.integrationsLoading.push(id);
      }
    },
    finishLoadingIntegration: (state, action) => {
      const id = String(action.payload.id);
      state.integrationsLoading = state.integrationsLoading.filter(
        integrationLoading => integrationLoading !== id,
      );
    },
    updateIntegrations: (state, action) => {
      const { addedIntegrations = [], deletedIntegrationsKeys = [] } = action.payload;

      state.lastUpdated = new Date().toUTCString();

      integrationsAdapter.upsertMany(state, addedIntegrations);
      integrationsAdapter.removeMany(state, deletedIntegrationsKeys);
    },
    updateIntegration: (state, action) => {
      const { id, props = {} } = action.payload;

      integrationsAdapter.updateOne(state, { id, changes: props });
    },

    updateAllowedGroups: (state, action) => {
      const { groupsIds, type = 'add' } = action.payload;
      const id = String(action.payload.id);
      if (!state.entities[id]) {
        return;
      }

      if (type === 'add') {
        return;
      }
      if (type === 'delete') {
        state.entities[id].allowed_groups = state.entities[id].allowed_groups.filter(
          group => !groupsIds.includes(group.id),
        );
      }
    },
  },
});

export const {
  initLoading,
  finishLoading,
  updateIntegrations,
  updateIntegration,
  updateAllowedGroups,
  initLoadingIntegration,
  finishLoadingIntegration,
} = integrationsSlice.actions;

// SELECTORS
const getIntegrationsState = state => state.integrations;
export const integrationsGlobalSelector = integrationsAdapter.getSelectors(getIntegrationsState);
export const getIntegrationsLastUpdated = createSelector(
  [getIntegrationsState],
  state => state.lastUpdated,
);
export const getIsIntegrationsLoading = createSelector(
  [getIntegrationsState],
  state => state.isLoading,
);
export const getIntegrationsList = createSelector([getIntegrationsState], state =>
  Object.values(state.entities).map(o => ({ ...o })),
);
export const getIntegrationsListByOrganisationId = createSelector(
  [getIntegrationsState, (_, organisationId) => organisationId],
  (state, organisationId) =>
    !organisationId
      ? Object.values(state.entities).map(integration => ({ ...integration }))
      : Object.values(state.entities)
          .filter(integration => integration.organisation_id === organisationId)
          .map(integration => ({ ...integration })),
);
export const getIntegrationsOptionsByOrganisation = createSelector(
  [getIntegrationsListByOrganisationId],
  integrations =>
    sortBy(
      integrations.map(integration => ({
        label: `${integration.name} Bot`,
        value: integration.appid,
      })),
      integration => cleanString(integration.label),
    ),
);
export const getIntegrationByKey = integrationsGlobalSelector.selectById;

export const getGroupsToAddByIntegrationKey = createSelector(
  integrationsGlobalSelector.selectEntities,
  (_, integrationKey) => integrationKey,
  groupsGlobalSelector.selectAll,
  (entities, integrationKey, groups) => {
    const integration = entities[integrationKey];

    const integrationGroupsIds = integration.allowed_groups.map(group => String(group.id));

    return groups.filter(group => !integrationGroupsIds.includes(group.id) && !group.federation);
  },
);

// THUNKS
export const fetchIntegrations = async dispatch => {
  dispatch(initLoading());

  try {
    const { data: integrations } = await AdminApi.getAllIntegrations();

    let addedIntegrations = [];
    let deletedIntegrationsKeys = [];

    addedIntegrations = integrations;

    batch(() => {
      dispatch(updateIntegrations({ addedIntegrations, deletedIntegrationsKeys }));
      dispatch(finishLoading());
    });
    return INTEGRATION_RESPONSES.OK;
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const fetchIntegration = id => async dispatch => {
  dispatch(initLoadingIntegration({ id }));
  try {
    const { data: integration } = await AdminApi.getIntegration(id);
    batch(() => {
      dispatch(
        updateIntegration({
          id,
          props: integration,
        }),
      );
    });
  } catch (e) {
    dispatch(showHttpError());
  } finally {
    dispatch(finishLoadingIntegration({ id }));
  }
};

export const createIntegration = ({ organisationId, name, comment }) => async dispatch => {
  try {
    const { data: integration } = await AdminApi.createIntegration({
      organisationId,
      name,
      comment,
    });
    dispatch(updateIntegrations({ addedIntegrations: [integration] }));
    return integration.id;
  } catch (errorIntegration) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const deleteIntegration = id => async dispatch => {
  try {
    await AdminApi.deleteIntegration(id);
    dispatch(updateIntegrations({ deletedIntegrationsKeys: [id] }));
    return INTEGRATION_RESPONSES.OK;
  } catch (e) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const updateIntegrationStatus = (id, action = 'enable') => async dispatch => {
  try {
    await AdminApi.updateIntegration({
      id,
      props: {
        state: action === 'enable' ? 'enabled' : 'disabled',
      },
    });
    dispatch(
      updateIntegration({
        id,
        props: {
          state: action === 'enable' ? 'Enabled' : 'Disabled',
        },
      }),
    );
    return INTEGRATION_RESPONSES.OK;
  } catch (e) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const updateIntegrationName = (id, name = '') => async dispatch => {
  const props = {
    name,
  };
  try {
    await AdminApi.updateIntegration({
      id,
      props,
    });
    dispatch(
      updateIntegration({
        id,
        props,
      }),
    );
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const updateIntegrationComment = (id, comment = '') => async dispatch => {
  const props = {
    comment,
  };
  try {
    await AdminApi.updateIntegration({
      id,
      props,
    });
    dispatch(
      updateIntegration({
        id,
        props,
      }),
    );
    return INTEGRATION_RESPONSES.OK;
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const updateIntegrationVisibility = (id, restrictVisibility = false) => async dispatch => {
  const props = {
    restrict_visibility: restrictVisibility,
  };
  try {
    await AdminApi.updateIntegration({
      id,
      props,
    });
    dispatch(
      updateIntegration({
        id,
        props,
      }),
    );
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const addAllowedGroups = (id, groupsIds) => async dispatch => {
  try {
    await AdminApi.addAllowedGroupToIntegration({ id, groupsIds });
    dispatch(fetchIntegration(id));
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export const removeAllowedGroup = (id, groupId) => async dispatch => {
  try {
    await AdminApi.removeAllowedGroupFromIntegration({ id, groupId });
    dispatch(
      updateAllowedGroups({
        type: 'delete',
        id,
        groupsIds: [groupId],
      }),
    );
    return INTEGRATION_RESPONSES.OK;
  } catch (error) {
    throw INTEGRATION_RESPONSES.ERROR;
  }
};

export default integrationsSlice.reducer;
