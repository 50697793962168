import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0.75, 0),
    flexDirection: 'column',
    '&$inline': {
      flexDirection: 'row',
      '& $element': {
        margin: theme.spacing(0, 0.75, 0, 0),
      },
    },
  },
  block: {},
  inline: {},
  element: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.primary.dark,
    margin: theme.spacing(0, 0, 0, 0.75),
  },
}));

const UserAbilities = ({ superAdmin, variant }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={clsx(classes.root, classes[variant])}>
      {superAdmin ? (
        <div className={classes.element}>
          <Star />
          <Typography variant="caption" className={classes.text}>
            {t('SUPER_ADMIN')}
          </Typography>
        </div>
      ) : null}
      {!superAdmin ? (
        <div className={classes.element}>
          <StarBorder />
          <Typography variant="caption" className={classes.text}>
            {t('admin')}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

UserAbilities.propTypes = {
  variant: PropTypes.oneOf(['rows', 'inline']),
};

UserAbilities.defaultProps = {
  variant: 'rows',
};

export default UserAbilities;
