import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import { getMyOrganisationId } from 'features/profile/profileSlice';

const divisionsSlice = createSlice({
  name: 'divisions',
  initialState: {
    divisions: [],
  },
  reducers: {
    updateDivisions: (state, action) => {
      state.divisions = action.payload;
    },
    addDivision: (state, action) => {
      state.divisions = [...state.divisions, action.payload];
    },
    removeDivision: (state, action) => {
      state.divisions = state.divisions.filter(division => division.id !== action.payload);
    },
  },
});

const divisionsState = state => state.divisions;
export const allDivisions = createSelector(divisionsState, state => state.divisions);

const { updateDivisions, addDivision, removeDivision } = divisionsSlice.actions;

const buildErrorResponse = response => {
  const { status, data } = response;
  if ([404, 403.1, 403.2, 403.3, 403.4, 403.5, 403.6].includes(status)) {
    return { ok: false, message: data.detail };
  }
  if (data.name) {
    return { ok: false, message: data.name };
  }
  return { ok: false };
};

export const fetchDivisions = (organisationId, filters = {}) => async (dispatch, getState) => {
  const { data } = await AdminApi.getAllDivisions({
    organisationId:
      organisationId === 'ALL_ORGANISATIONS' ? getMyOrganisationId(getState()) : organisationId,
    filters: { offset: 0, limit: 100, ...filters },
  });
  dispatch(updateDivisions(data.results));
};

export const createDivision = (organisationId, divisionName) => async (dispatch, getState) => {
  try {
    const result = await AdminApi.addDivision({
      organisationId:
        organisationId === 'ALL_ORGANISATIONS' ? getMyOrganisationId(getState()) : organisationId,
      divisionName,
    });
    await dispatch(addDivision(result.data));
    return { ok: true };
  } catch (error) {
    return buildErrorResponse(error.response);
  }
};

export const deleteDivision = (organisationId, divisionId) => async (dispatch, getState) => {
  try {
    await AdminApi.deleteDivision({
      organisationId:
        organisationId === 'ALL_ORGANISATIONS' ? getMyOrganisationId(getState()) : organisationId,
      divisionId,
    });
    await dispatch(removeDivision(divisionId));
    return { ok: true };
  } catch (error) {
    return buildErrorResponse(error.response);
  }
};

export const getDivisionByName = divisionName => (dispatch, getState) => {
  const { divisions: state } = getState();
  return state.divisions.find(division => division.name === divisionName);
};

export default divisionsSlice.reducer;
