import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminApi from 'api/AdminApi';
import { resetApp } from 'features/auth/authSlice';

const ALL_ORGANISATIONS_KEY = 'ALL_ORGANISATIONS';

export const DASHBOARD_RESPONSES = {
  OK: 'ok',
  GENERIC_ERROR: 'generic_error',
  BAD_REQUEST: 'bad_request',
  FORBIDDEN: 'forbidden',
  NOT_FOUND: 'not_found',
};

export const dashboardInitialState = {
  isLoading: false,
  dashboardData: false,
  error: null,
  activeUsersLoading: false,
  activeUsersData: null,
  activeUsersError: null,
  activeDevicesLoading: false,
  activeDevicesData: null,
  activeDevicesError: null,
  sentMessagesLoading: false,
  sentMessagesData: null,
  sentMessagesError: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardInitialState,
  reducers: {
    dashboardRequest: state => {
      state.isLoading = true;
    },
    dashboardFinished: state => {
      state.isLoading = false;
    },
    dashboardSuccess: (state, action) => {
      state.dashboardData = action.payload.data;
      state.isLoading = false;
    },
    dashboardFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload || DASHBOARD_RESPONSES.GENERIC_ERROR;
    },
    activeUsersRequest: state => {
      state.activeUsersLoading = true;
    },
    activeUsersFinished: state => {
      state.activeUsersLoading = false;
    },
    activeUsersSuccess: (state, action) => {
      state.activeUsersData = action.payload.data;
      state.activeUsersLoading = false;
    },
    activeUsersFailed: (state, action) => {
      state.activeUsersLoading = false;
      state.activeUsersError = action.payload || DASHBOARD_RESPONSES.GENERIC_ERROR;
    },
    activeDevicesRequest: state => {
      state.activeDevicesLoading = true;
    },
    activeDevicesFinished: state => {
      state.activeDevicesLoading = false;
    },
    activeDevicesSuccess: (state, action) => {
      state.activeDevicesData = action.payload.data;
      state.activeDevicesLoading = false;
    },
    activeDevicesFailed: (state, action) => {
      state.activeDevicesLoading = false;
      state.activeDevicesError = action.payload || DASHBOARD_RESPONSES.GENERIC_ERROR;
    },
    sentMessagesRequest: state => {
      state.sentMessagesLoading = true;
    },
    sentMessagesFinished: state => {
      state.sentMessagesLoading = false;
    },
    sentMessagesSuccess: (state, action) => {
      state.sentMessagesData = action.payload.data;
      state.sentMessagesLoading = false;
    },
    sentMessagesFailed: (state, action) => {
      state.sentMessagesLoading = false;
      state.sentMessagesError = action.payload || DASHBOARD_RESPONSES.GENERIC_ERROR;
    },
  },
});

export const {
  dashboardRequest,
  dashboardSuccess,
  dashboardFailed,
  dashboardFinished,
  activeUsersRequest,
  activeUsersSuccess,
  activeUsersFailed,
  activeUsersFinished,
  activeDevicesRequest,
  activeDevicesSuccess,
  activeDevicesFailed,
  activeDevicesFinished,
  sentMessagesRequest,
  sentMessagesSuccess,
  sentMessagesFailed,
  sentMessagesFinished,
} = dashboardSlice.actions;

export const doGetDashboardData = async dispatch => {
  try {
    dispatch(dashboardRequest());
    const result = await AdminApi.getDashboardStats();
    dispatch(dashboardSuccess({ data: result.data }));

    return DASHBOARD_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.BAD_REQUEST));
        return DASHBOARD_RESPONSES.BAD_REQUEST;
      }

      if (status === 401) {
        dispatch(resetApp());
      }

      if (status === 403) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.FORBIDDEN));
        return DASHBOARD_RESPONSES.FORBIDDEN;
      }
    }
    dispatch(dashboardFailed(DASHBOARD_RESPONSES.GENERIC_ERROR));
    return DASHBOARD_RESPONSES.GENERIC_ERROR;
  }
};

export const doGetStatsActiveUsers = (month, year, selectedOrganisationId) => async dispatch => {
  const organisationId =
    selectedOrganisationId === ALL_ORGANISATIONS_KEY ? '' : selectedOrganisationId;
  try {
    dispatch(activeUsersRequest());
    const result = await AdminApi.getStatsActiveUsers({ month, year, organisationId });
    dispatch(activeUsersSuccess({ data: result.data }));

    return DASHBOARD_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(activeUsersFailed(DASHBOARD_RESPONSES.BAD_REQUEST));
        return DASHBOARD_RESPONSES.BAD_REQUEST;
      }

      if (status === 401) {
        dispatch(resetApp());
      }

      if (status === 403) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.FORBIDDEN));
        return DASHBOARD_RESPONSES.FORBIDDEN;
      }

      if (status === 404) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.NOT_FOUND));
        return DASHBOARD_RESPONSES.NOT_FOUND;
      }
    }
    dispatch(activeUsersFailed(DASHBOARD_RESPONSES.GENERIC_ERROR));
    return DASHBOARD_RESPONSES.GENERIC_ERROR;
  }
};

export const doGetStatsActiveDevices = (month, year, selectedOrganisationId) => async dispatch => {
  const organisationId =
    selectedOrganisationId === ALL_ORGANISATIONS_KEY ? '' : selectedOrganisationId;
  try {
    dispatch(activeDevicesRequest());
    const result = await AdminApi.getStatsActiveDevices({ month, year, organisationId });
    dispatch(activeDevicesSuccess({ data: result.data }));

    return DASHBOARD_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(activeDevicesFailed(DASHBOARD_RESPONSES.BAD_REQUEST));
        return DASHBOARD_RESPONSES.BAD_REQUEST;
      }

      if (status === 401) {
        dispatch(resetApp());
      }

      if (status === 403) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.FORBIDDEN));
        return DASHBOARD_RESPONSES.FORBIDDEN;
      }

      if (status === 404) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.NOT_FOUND));
        return DASHBOARD_RESPONSES.NOT_FOUND;
      }
    }
    dispatch(activeDevicesFailed(DASHBOARD_RESPONSES.GENERIC_ERROR));
    return DASHBOARD_RESPONSES.GENERIC_ERROR;
  }
};

export const doGetStatsSentMessages = (month, year, selectedOrganisationId) => async dispatch => {
  const organisationId =
    selectedOrganisationId === ALL_ORGANISATIONS_KEY ? '' : selectedOrganisationId;
  try {
    dispatch(sentMessagesRequest());
    const result = await AdminApi.getStatsSentMessages({ month, year, organisationId });
    dispatch(sentMessagesSuccess({ data: result.data }));

    return DASHBOARD_RESPONSES.OK;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 400) {
        dispatch(sentMessagesFailed(DASHBOARD_RESPONSES.BAD_REQUEST));
        return DASHBOARD_RESPONSES.BAD_REQUEST;
      }

      if (status === 401) {
        dispatch(resetApp());
      }

      if (status === 403) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.FORBIDDEN));
        return DASHBOARD_RESPONSES.FORBIDDEN;
      }

      if (status === 404) {
        dispatch(dashboardFailed(DASHBOARD_RESPONSES.NOT_FOUND));
        return DASHBOARD_RESPONSES.NOT_FOUND;
      }
    }
    dispatch(sentMessagesFailed(DASHBOARD_RESPONSES.GENERIC_ERROR));
    return DASHBOARD_RESPONSES.GENERIC_ERROR;
  }
};

const getDashboardState = state => state.dashboard;

export const getDashboardLoading = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.isLoading,
);
export const getDashboardData = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.dashboardData,
);
export const getDashboardError = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.error,
);

export const getActiveUsersLoading = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeUsersLoading,
);
export const getActiveUsersData = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeUsersData,
);
export const getActiveUsersError = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeUsersError,
);

export const getActiveDevicesLoading = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeDevicesLoading,
);
export const getActiveDevicesData = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeDevicesData,
);
export const getActiveDevicesError = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.activeDevicesError,
);

export const getSentMessagesLoading = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.sentMessagesLoading,
);
export const getSentMessagesData = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.sentMessagesData,
);
export const getSentMessagesError = createSelector(
  [getDashboardState],
  dashboardState => dashboardState.sentMessagesError,
);

export default dashboardSlice.reducer;
